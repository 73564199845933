import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const selector = (state) => state.auth;

const Home2 = () => {
  const { user, isLoggedIn } = useSelector(selector);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is logged in.
    if (!isLoggedIn || isLoggedIn == null) {
      // If not, redirect to login form.
      navigate("/form-login");
    } else if (user.authority === "ROLE_ADMIN") {
      navigate("/admin/doctors");
    } else if (user.authority === "ROLE_DOCTOR") {
      // If the user is a doctor, show the list of their patients.
      navigate("/doctor/patients");
    } else if (user.authority === "ROLE_PATIENT") {
      // If the user is a patient, show their info page.
      navigate("/patient");
    }
  }, [isLoggedIn, navigate, user]);
  return <>error</>;
};

export default Home2;
