import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDoctors } from "../../actions/doctor";
import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Delete } from "@mui/icons-material";
import AlertDialog from "../util/AlertDialog.js";

const selector = (state) => state.auth;

function HomeAdmin() {
  const thisroute = useLocation();
  const [doctorsListLoading, setDoctorsListLoading] = useState(true);
  const dispatch = useDispatch();
  const { isLoggedIn, doctorsList } = useSelector(selector);
  const navigate = useNavigate();
  const [doctorId, setDoctorId] = useState(null);

  useEffect(() => {
    if (isLoggedIn) {
      if (doctorsListLoading) {
        setDoctorsListLoading(false);
        dispatch(getDoctors());
      }
    } else {
      navigate("/form-login");
    }
  }, [doctorsListLoading, isLoggedIn, doctorsList, navigate, dispatch]);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const columnsNew = [
    {
      field: "firstName",
      headerName: <strong>Nome</strong>,
      align: "left",
      width: 200,
    },
    {
      field: "lastName",
      headerName: <strong>Cognome</strong>,
      align: "left",
      width: 200,
    },
    {
      field: "email",
      headerName: <strong>Email</strong>,
      align: "left",
      width: 200,
    },
    {
      field: "ssn",
      headerName: <strong>Codice Fiscale</strong>,
      align: "left",
      width: 200,
    },
    {
      field: "delete",
      headerName: "",
      align: "center",
      width: 100,
      renderCell: (cellValue) => (
        <Button color="error">
          <Delete></Delete>
        </Button>
      ),
    },
  ];

  const [anchorDoctorMenu, setAnchorDoctorMenu] = useState(null);

  return (
    <>
      <AlertDialog
        open={open}
        setOpen={setOpen}
        title="Elimina dottore"
        message="Sei sicuro di voler eliminare il dottore?"
        doctorId={doctorId}
        action="delete_doctor"
      ></AlertDialog>
      <Menu
        anchorEl={anchorDoctorMenu}
        open={Boolean(anchorDoctorMenu)}
        onClose={() => {
          setAnchorDoctorMenu(null);
        }}
      >
        <MenuItem onClick={() => {}}>Questionari</MenuItem>
      </Menu>

      <div
        style={{
          height: 500,
          maxWidth: "calc(100vw - 302px)",
          marginLeft: "32px",
        }}
      >
        <Box
          style={{ width: "100%", textAlign: "left", paddingBottom: "14px" }}
        >
          <Button
            startIcon={<PersonAddAltIcon></PersonAddAltIcon>}
            sx={{ marginRight: "8px" }}
            title="aggiungi dottore"
            variant="outlined"
            onClick={() => {
              navigate(thisroute.pathname + "/new");
            }}
          >
            Aggiungi dottore
          </Button>
          <Button
            startIcon={<FileDownloadIcon></FileDownloadIcon>}
            disabled
            variant="outlined"
            title="esporta lista"
            onClick={() => {}}
          >
            Esporta
          </Button>
        </Box>
        <DataGrid
          rows={doctorsList}
          columns={columnsNew}
          pageSize={10}
          loading={doctorsListLoading === true}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[10, 20, 50]}
          disableRowSelectionOnClick
          onCellClick={(cellValue) => {
            if (cellValue.field === "delete") {
              setDoctorId(cellValue.row.id);
              handleClickOpen();
            }
          }}
        />
      </div>
    </>
  );
}

export { HomeAdmin };
