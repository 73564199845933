import React from "react";
import { BrowserRouter as Router, useRoutes } from "react-router-dom";
import Sidebar2 from "./components/Sidebar";
import Home2 from "./components/Home";
import { Box } from "@mui/material";
import FormLogin from "./components/forms/FormLogin";
import { Questionnaires } from "./components/doctor/Questionnaires";
import { HomeDoctor } from "./components/doctor/HomeDoctor";
import { PatientDetail } from "./components/doctor/PatientDetail";
import { CreateQuestionnaire } from "./components/admin/CreateQuestionnaire";
import { CreatePatient } from "./components/doctor/CreatePatient";
import { My404Component } from "./components/My404Component";
import { BreadCrumbs } from "./components/BreadCrumbs";
import { AuthComponent } from "./components/UserComponent";
import { CompileQuestionnaire } from "./components/doctor/CompileQuestionnaire";
import { QuestionnairePatient } from "./components/doctor/QuestionnairePatient";
import { QuestionnaireTemplate } from "./components/admin/QuestionnaireTemplate";
import { SnackbarProvider } from "notistack";
import { FitbitLogin } from "./components/patient/FitbitLogin";
import { HomePatient } from "./components/patient/HomePatient";
import { HomeAdmin } from "./components/admin/HomeAdmin";
import SuccessFitbit from "./components/SuccessFitbit";
import ErrorFitbit from "./components/ErrorFitbit";
import { useSelector } from "react-redux";
import { CreateDoctor } from "./components/admin/CreateDoctor";
import { ChangePasswordForm } from "./components/forms/ChangePasswordForm";
import { ResetPasswordPage } from "./components/forms/ResetPasswordForm";
import { ChangePasswordError } from "./components/ChangePasswordError";
import ResponsiveAppBar from "./components/ResponsiveAppBar";

// Define the routes path and the corresponding element.
function Routes() {
  const routes = useRoutes([
    { path: "/", element: <Home2 /> },
    { path: "/form-login", element: <FormLogin /> },
    { path: "/success-fitbit", element: <SuccessFitbit /> },
    { path: "/error-fitbit", element: <ErrorFitbit /> },
    { path: "/reset-password", element: <ResetPasswordPage /> },
    { path: "/change-password-form/:token", element: <ChangePasswordForm /> },
    { path: "/change-password-error", element: <ChangePasswordError /> },
    {
      path: "/patient",
      children: [
        { index: true, element: <HomePatient /> },
        { path: "fitbit-login", element: <FitbitLogin /> },
      ],
    },
    {
      path: "/admin",
      children: [
        {
          path: "doctors",
          children: [
            { index: true, element: <HomeAdmin /> },
            { path: "new", element: <CreateDoctor /> },
          ],
        },
        {
          path: "questionnaires",
          children: [
            {
              index: true,
              element: (
                <div>
                  <Questionnaires />
                </div>
              ),
            },
            { path: "new", element: <CreateQuestionnaire /> },
            { path: ":questionnaireId", element: <QuestionnaireTemplate /> },
          ],
        },
      ],
    },
    {
      path: "/doctor",
      children: [
        {
          path: "patients",
          children: [
            { index: true, element: <HomeDoctor /> },
            { path: "new", element: <CreatePatient /> },
            {
              path: ":patientId",
              children: [
                { index: true, element: <PatientDetail /> },
                {
                  path: "questionnaires",
                  children: [
                    { path: "new", element: <CompileQuestionnaire /> },
                    {
                      path: ":questionnaireId",
                      element: <QuestionnairePatient />,
                    },
                  ],
                },
                { path: "update", element: <CreatePatient /> },
              ],
            },
          ],
        },
      ],
    },
    { path: "*", element: <My404Component /> },
  ]);
  return routes;
}

const selector = (state) => state.auth;

function App() {
  const { isLoggedIn } = useSelector(selector);
  return (
    <Router>
      <div className="container-fluid">
        <ResponsiveAppBar />
        <Box sx={{ display: "flex" }}>
          {isLoggedIn && <Sidebar2 />}
          <Box component="main" style={{ marginTop: "64px" }}>
            <SnackbarProvider
              maxSnack={3}
              anchorOrigin={{ horizontal: "center", vertical: "top" }}
            >
              <div
                style={{
                  position: "fixed",
                  width: "100%",
                  zIndex: 2,
                  backgroundColor: "white",
                }}
              >
                {isLoggedIn && <BreadCrumbs />}
              </div>
              <AuthComponent></AuthComponent>
              <div
                style={{
                  marginTop: "72px",
                  backgroundColor: "white",
                  width: "100%",
                }}
              >
                <Routes />
              </div>
            </SnackbarProvider>
          </Box>
        </Box>
      </div>
    </Router>
  );
}

export default App;
