import questionnaireService from "../services/questionnaire.service";
export const createQuestionnaire = (questionnaire) => (dispatch) => {
  return questionnaireService.createQuestionnaire(questionnaire).then(
    (response) => {
      return Promise.resolve(response);
    },
    (error) => {
      dispatch({
        type: "ERROR",
        payload: error.message,
      });
      return Promise.resolve(error);
    },
  );
};

export const getQuestionnairesTemplates = () => (dispatch) => {
  questionnaireService.getQuestionnairesTemplates().then((data) => {
    try {
      var json = JSON.parse(JSON.stringify(data.data));
      if (!json || typeof json !== "object") {
        dispatch({
          type: "LOGOUT",
          payload: null,
        });
        return;
      }
    } catch (e) {
      dispatch({
        type: "LOGOUT",
        payload: null,
      });
    }
    dispatch({
      type: "QUESTIONNAIRES",
      payload: data.data,
    });
  });
};

export const getQuestionnaireTemplate = (questionnaireId) => (dispatch) => {
  questionnaireService
    .getQuestionnaireTemplate(questionnaireId)
    .then((data) => {
      try {
        var json = JSON.parse(JSON.stringify(data.data));
        if (!json || typeof json !== "object") {
          dispatch({
            type: "LOGOUT",
            payload: null,
          });
          return;
        }
      } catch (e) {
        dispatch({
          type: "LOGOUT",
          payload: null,
        });
      }
      dispatch({
        type: "QUESTIONNAIRE",
        payload: data.data,
      });
    });
};
