import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  ListItemText,
  List,
  ListItem,
  ListItemIcon,
  ListItemButton,
  Drawer,
} from "@mui/material";
import { icon_actions } from "./sidebar_actions.js";
import { Footer } from "./Footer.js";

const selector = (state) => state.auth;

const Sidebar2 = () => {
  const { user, menu } = useSelector(selector);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <>
      <Drawer
        sx={{
          width: 240,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            marginTop: 10,
            width: 240,
            boxSizing: "border-box",
            paddingBottom: 100,
            overflowY: "hidden",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        {menu != null ? (
          <List>
            {" "}
            {menu[user?.authority]?.map((voiceMenu, index) => (
              <ListItem key={voiceMenu.label} disablePadding>
                <ListItemButton
                  onClick={() => {
                    icon_actions[user.authority][voiceMenu.label].action(
                      navigate,
                    );
                    dispatch({
                      type: "MENU_ACTION",
                      payload: {
                        authority: user.authority,
                        action: voiceMenu.label,
                      },
                    });
                  }}
                  selected={voiceMenu.active}
                >
                  <ListItemIcon>
                    {icon_actions[user.authority][voiceMenu.label]?.icon}
                  </ListItemIcon>
                  <ListItemText primary={voiceMenu.label} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        ) : null}
        <Footer />
      </Drawer>
    </>
  );
};

export default Sidebar2;
