import { Alert } from "@mui/material";
import * as React from "react";

function My404Component(props) {
  return (
    <>
      <div
        style={{
          height: 500,
          width: "calc(100vw - 302px)",
          marginLeft: "32px",
        }}
      >
        <Alert severity="error" variant="outlined">
          la pagina che stai cercando non esiste
        </Alert>
      </div>
    </>
  );
}

export { My404Component };
