import { useNavigate, useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import { Breadcrumbs, IconButton, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { ArrowBack } from "@mui/icons-material";

const selector = (state) => state.auth;

function BreadCrumbs(props) {
  const { isLoggedIn } = useSelector(selector);
  let location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {}, [location]);

  return (
    <div style={{ display: "flex", marginLeft: "16px" }}>
      {isLoggedIn ? (
        <IconButton color="primary" onClick={() => navigate(-1)}>
          <ArrowBack></ArrowBack>
        </IconButton>
      ) : (
        <></>
      )}
      <Breadcrumbs
        style={{
          marginLeft: "32px",
          marginBottom: "24px",
          marginTop: "24px",
        }}
        aria-label="breadcrumb"
      >
        {location.pathname.split("/").map((path) => {
          return <Typography key={"id" + path}>{path}</Typography>;
        })}
      </Breadcrumbs>
    </div>
  );
}

export { BreadCrumbs };
