import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import { useState } from "react";
import { fitbitLogin } from "../../services/auth.services";
import { useSelector } from "react-redux";

const selector = (state) => state.auth;

function FitbitLogin(props) {
  const [loading, setLoading] = useState(false);

  const { user } = useSelector(selector);

  function handleFitbitLogin() {
    setLoading(true);
    fitbitLogin(user?.id);
  }

  return (
    <>
      <Box sx={{ width: "calc(100vw - 302px)", marginLeft: "32px" }}>
        <h2>
          Clicca qui per procedere su Fitbit e completare la registrazione
        </h2>
        <LoadingButton
          variant="contained"
          loading={loading}
          onClick={() => handleFitbitLogin()}
        >
          Procedi
        </LoadingButton>
      </Box>
    </>
  );
}

export { FitbitLogin };
