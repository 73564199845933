import { PATIENTS_ICON, QUESTIONNAIRE_ICON } from "./util/EnumSidebarItem";

export const icon_actions = {
  ROLE_ADMIN: {
    Dottori: {
      icon: PATIENTS_ICON,
      action: (navigate) => navigate("/admin/doctors"),
    },
    Questionari: {
      icon: QUESTIONNAIRE_ICON,
      action: (navigate) => navigate("/admin/questionnaires"),
    },
  },
  ROLE_DOCTOR: {
    Pazienti: {
      icon: PATIENTS_ICON,
      action: (navigate) => navigate("/doctor/patients"),
    },
  },
};

export const menu_actions = {
  ROLE_DOCTOR: [
    {
      label: "Pazienti",
      active: true,
    },
  ],
  ROLE_ADMIN: [
    {
      label: "Dottori",
      active: true,
    },
    {
      label: "Questionari",
      active: false,
    },
  ],
  guest: [],
  patient: [{}],
};
