import logo from "../icons/daily-health-app.png";
import { Box, Grid } from "@mui/material";

const ChangePasswordError = () => {
  return (
    <Box
      style={{
        width: "100vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "80vh",
      }}
    >
      <Grid
        container
        spacing={3}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "50vh" }}
      >
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <h2>VERONA EXPERIMENT</h2>
          <img src={logo} width={"60px"} alt="" />
          <h2>CAMBIO PASSWORD</h2>
          <h2>ERRORE, TOKEN NON VALIDO</h2>
        </Grid>
      </Grid>
    </Box>
  );
};

export { ChangePasswordError };
