import { Alert } from "@mui/material";

const SuccessFitbit = () => {
  return (
    <>
      <div
        style={{
          height: 500,
          width: "calc(100vw - 302px)",
          marginLeft: "32px",
        }}
      >
        <Alert sx={{ width: "fitContent" }} severity="success">
          Registrazione completata con successo. Ora puoi accedere
          all&apos;applicazione mobile. Ricorda di cambiare la password.
        </Alert>
      </div>
    </>
  );
};

export default SuccessFitbit;
