import { Box, Container, Paper, Typography } from "@mui/material";

// The value of appVersion is substituted runtime
// with the REACT_APP_VERSION env var value.
const appVersion = "${REACT_APP_VERSION}"; // eslint-disable-line no-template-curly-in-string

const Footer = () => {
  return (
    <Paper
      sx={{
        marginTop: "calc(10% + 60px)",
        width: "238px",
        position: "fixed",
        bottom: 0,
      }}
      component="footer"
      square
      variant="outlined"
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            flexGrow: 1,
            justifyContent: "center",
            display: "flex",
            my: 1,
          }}
        ></Box>

        <Box
          sx={{
            flexGrow: 1,
            justifyContent: "center",
            display: "flex",
            mb: 2,
            textAlign: "center",
          }}
        >
          <Typography variant="caption">
            © {new Date().getFullYear()} HealthApp.
            <br />
            All rights reserved. <br />
            {appVersion && (
              <div style={{ color: "grey" }}>Version: {appVersion}</div>
            )}
          </Typography>
        </Box>
      </Container>
    </Paper>
  );
};

export { Footer };
