import patientService from "../services/patient.service";
import dayjs from "dayjs";
import { enqueueSnackbar } from "notistack";

export const getPatientWeights = (id, startDate, endDate) => (dispatch) => {
  return patientService.getPatientWeights(id).then(
    (data) => {
      if (data.data instanceof Array) {
        dispatch({
          type: "PATIENT_WEIGHTS",
          payload: data.data,
        });
      } else {
        dispatch({
          type: "LOGOUT",
        });
      }
    },
    (err) => {},
  );
};

export const compileQuestionnaire = (id, questionnaire) => (dispatch) => {
  return patientService.compileQuestionnaire(id, questionnaire).then(
    (data) => {
      return Promise.resolve(data);
    },
    (err) => {
      dispatch({
        type: "ERROR",
        payload: err,
      });
      return Promise.reject(err);
    },
  );
};

export const deletePatient = (id) => (dispatch) => {
  return patientService.deletePatient(id).then(
    (data) => {
      dispatch({
        type: "DELETE_PATIENT",
        payload: id,
      });
      enqueueSnackbar("Paziente eliminato con successo", {
        variant: "success",
      });
    },
    (err) => {
      dispatch({
        type: "ERROR",
        payload: err,
      });
      return Promise.reject(err);
    },
  );
};

export const getPatientQuestionnaires = (id) => (dispatch) => {
  return patientService.getPatientQuestionnaires(id).then(
    (data) => {
      if (data.data instanceof Array) {
        dispatch({
          type: "PATIENT_QUESTIONNAIRES",
          payload: data.data,
        });
      } else {
        dispatch({
          type: "LOGOUT",
        });
      }
    },
    (err) => {},
  );
};

export const getPatientBloodAnalysis = (id) => (dispatch) => {
  return patientService.getPatientBloodAnalysis(id).then(
    (data) => {
      if (data.data instanceof Array) {
        dispatch({
          type: "PATIENT_BLOOD_ANALYSIS",
          payload: data.data,
        });
      } else {
        dispatch({
          type: "LOGOUT",
        });
      }
    },
    (err) => {
      dispatch({
        type: "ERROR",
        payload: "Errore di ricezione dati su analisi del sangue",
      });
    },
  );
};

export const insertStepsThreshold = (patientId, value) => (dispatch) => {
  return patientService.insertStepsThreshold(patientId, value).then(
    (result) => {
      dispatch(getPatientStepsThreshold(patientId));
    },
    (error) => {
      dispatch({
        type: "ERROR",
        payload: "Errore nella ricezione dei valori soglia dei passi",
      });
    },
  );
};

export const getPatientQuestionnaire = (pid, qid) => (dispatch) => {
  return patientService.getPatientQuestionnaire(pid, qid).then((data) => {
    if (data.data instanceof Object) {
      dispatch({
        type: "PATIENT_QUESTIONNAIRE",
        payload: data.data,
      });
    } else {
      dispatch({
        type: "LOGOUT",
      });
    }
  });
};

export const getPatient = (id) => (dispatch) => {
  return patientService.getPatient(id).then((data) => {
    if (data.data instanceof Object) {
      dispatch({
        type: "PATIENT",
        payload: data.data,
      });
    } else {
      dispatch({
        type: "LOGOUT",
      });
    }
  });
};

export const getPatients = () => (dispatch) => {
  patientService.getPatients().then(
    (data) => {
      try {
        var json = JSON.parse(JSON.stringify(data.data));
        if (!json || typeof json !== "object") {
          dispatch({
            type: "LOGOUT",
            payload: null,
          });
          return;
        }
      } catch (e) {
        dispatch({
          type: "LOGOUT",
          payload: null,
        });
      }
      dispatch({
        type: "PATIENTS",
        payload: data.data,
      });
    },
    (error) => {},
  );
};

export const getPatientCalories = (id, startDate, endDate) => (dispatch) => {
  return patientService
    .getPatientCalories(id, startDate, endDate)
    .then((data) => {
      dispatch({
        type: "PATIENT_CALORIES",
        payload: data.data,
      });
    });
};

export const getPatientSleep = (id, startDate, endDate) => (dispatch) => {
  return patientService.getPatientSleep(id, startDate, endDate).then((data) => {
    if (data.data instanceof Array) {
      data.data = data.data.map((item) => {
        return {
          ...item,
          sleep: item.durationMs / 1000 / 60 - item.minWake,
          date: item.id.date,
        };
      });
      const sleepAvarage = avg(data.data, "sleep");
      data.data = adjustData(startDate, endDate, data.data, [
        "sleep",
        "minDeep",
        "minLight",
        "minRem",
        "minWake",
      ]);
      dispatch({
        type: "PATIENT_SLEEP",
        payload: data.data,
      });
      dispatch({
        type: "PATIENT_SLEEP_AVG",
        payload: sleepAvarage,
      });
    } else {
      dispatch({
        type: "LOGOUT",
      });
    }
  });
};

export const getBloodAnalysisRange = (patientId) => (dispatch) => {
  return patientService.getBloodAnalysisRange(patientId).then(
    (data) => {
      dispatch({
        type: "BLOOD_ANALYSIS_RANGE",
        payload: data.data.length > 0 ? data.data.pop() : null,
      });
    },
    (error) => {
      dispatch({
        type: "ERROR",
        payload: error.message,
      });
    },
  );
};

export const getPatientActivity = (id, startDate, endDate) => (dispatch) => {
  return patientService.getPatientActivity(id, startDate, endDate).then(
    (data) => {
      if (data.data instanceof Array) {
        dispatch({
          type: "PATIENT_ACTIVITY",
          payload: data.data,
        });
      } else {
        dispatch({
          type: "LOGOUT",
        });
      }
    },
    (err) => {
      dispatch({
        type: "ERROR",
        payload: "Errore di ricezione dati su attività fisica",
      });
    },
  );
};

export const insertPatientWeight = (id, data) => (dispatch) => {
  return patientService.insertPatientWeight(id, data).then(
    (data) => {
      return data;
    },
    (err) => {
      dispatch({
        type: "ERROR",
        payload: "Errore nell'inserimento del peso",
      });
    },
  );
};

export const updatePatientWeight = (patientId, data, idW) => (dispatch) => {
  return patientService.updatePatientWeight(patientId, data, idW).then(
    (data) => {
      return data;
    },
    (err) => {
      dispatch({
        type: "ERROR",
        payload: "Errore nell'aggiornamento del peso",
      });
    },
  );
};

export const insertBloodAnalysis = (id, data) => (dispatch) => {
  return patientService.insertBloodAnalysis(id, data).then(
    (data) => {
      return data;
    },
    (err) => {
      dispatch({
        type: "ERROR",
        payload: "Errore nell'inserimento dell'analisi del sangue",
      });
    },
  );
};

export const getPatientStepsThreshold = (id) => (dispatch) => {
  return patientService.getPatientStepsThreshold(id).then(
    (data) => {
      if (data.data instanceof Object) {
        dispatch({
          type: "PATIENT_STEPS_THRESHOLD",
          payload: data.data,
        });
      } else {
        dispatch({
          type: "LOGOUT",
        });
      }
    },
    (error) => {
      dispatch({
        type: "ERROR",
        payload: "Errore nella ricezione dei valori soglia dei passi",
      });
    },
  );
};

export const getPatientSteps = (id, startDate, endDate) => (dispatch) => {
  return patientService.getPatientSteps(id, startDate, endDate).then((data) => {
    if (data.data instanceof Array) {
      const stepsAvarage = avg(data.data, "steps");
      data.data = adjustData(startDate, endDate, data.data, ["steps"]);
      dispatch({
        type: "PATIENT_STEPS",
        payload: data.data,
      });
      dispatch({
        type: "PATIENT_STEPS_AVG",
        payload: stepsAvarage.toFixed(2),
      });
    } else {
      dispatch({
        type: "LOGOUT",
      });
    }
  });
};

export const getPatientHRS = (id, startDate, endDate) => (dispatch) => {
  return patientService.getPatientHRS(id, startDate, endDate).then((data) => {
    data.data = convert(data.data.days);
    const rhrAverage = mediana(data.data, "rest_rate");
    data.data = adjustData(startDate, endDate, data.data, [
      "rest_rate",
      "low_time",
      "mid_time",
      "high_time",
    ]);
    dispatch({
      type: "PATIENT_HRS",
      payload: data.data,
    });
    dispatch({
      type: "PATIENT_HEARTH_AVG",
      payload: rhrAverage,
    });
  });
};

function mediana(data, field) {
  // Filtra gli elementi con valore non zero nel campo specificato
  const filteredData = data.filter((item) => item[field] !== 0);

  if (filteredData.length === 0) {
    return 0;
  }

  const sorted = filteredData.sort((a, b) => a[field] - b[field]);
  const half = Math.floor(sorted.length / 2);

  if (sorted.length % 2) {
    return sorted[half][field];
  } else {
    return (sorted[half - 1][field] + sorted[half][field]) / 2.0;
  }
}

function avg(data, field) {
  if (data.length === 0) {
    return 0;
  }
  let sum = 0;
  for (let i = 0; i < data.length; i++) {
    sum += data[i][field];
  }
  return sum / data.length;
}

export const getSleepThreshold = (patientId) => (dispatch) => {
  return patientService.getSleepThreshold(patientId).then(
    (result) => {
      dispatch({
        type: "SLEEP_THRESHOLD",
        payload: (result.data[0].durationMsThreshold / 1000 / 60 / 60).toFixed(
          2,
        ),
      });
    },
    (error) => {
      dispatch({
        type: "ERROR",
        payload: "Errore nella ricezione dei valori soglia del sonno",
      });
    },
  );
};

export const getHearthThreshold = (patientId) => (dispatch) => {
  return patientService.getHearthThreshold(patientId).then(
    (result) => {
      dispatch({
        type: "HR_THRESHOLD",
        payload: result.data[0].restMin + " - " + result.data[0].restMax,
      });
    },
    (error) => {
      dispatch({
        type: "ERROR",
        payload:
          "Errore nella ricezione dei valori soglia dei battiti cardiaci",
      });
    },
  );
};

export const insertHearhThreshold = (patientId, hrThreshold) => (dispatch) => {
  patientService.insertHearhThreshold(patientId, hrThreshold).then((result) => {
    dispatch(getHearthThreshold(patientId));
  });
};

export const insertSleepThreshold =
  (patientId, sleepThreshold) => (dispatch) => {
    patientService
      .insertSleepThreshold(patientId, sleepThreshold)
      .then((result) => {
        dispatch(getSleepThreshold(patientId));
      });
  };

export const insertBloodAnalysisThresholds =
  (patientId, bloodAnalysisThresholds) => (dispatch) => {
    patientService
      .insertBloodAnalysisThresholds(patientId, bloodAnalysisThresholds)
      .then(
        (result) => {
          dispatch(getBloodAnalysisRange(patientId));
        },
        (error) => {
          dispatch({
            type: "ERROR",
            payload: error.message,
          });
        },
      );
  };

function convert(days) {
  return Object.keys(days).map((date) => ({
    date: date,
    rest_rate: days[date].rest_rate,
    low_time: days[date].low_time,
    mid_time: days[date].mid_time,
    high_time: days[date].high_time,
  }));
}

export function adjustData(startDate, endDate, data, fields) {
  const targetDate = dayjs(endDate).format("YYYY-MM-DD");

  // Trova l'indice dell'elemento con la data target, se esiste
  const existingDataIndex = data.findIndex((item) => item.date === targetDate);

  // Se la data target non esiste in data, aggiungila
  if (existingDataIndex === -1) {
    let value = {
      date: targetDate,
    };
    for (let field of fields) {
      value[field] = 0;
    }
    data.push(value);
  }

  // Crea un array di tutte le date presenti in data
  const existingDates = data.map((item) => item.date);

  // Crea un array di tutte le date nell'intervallo tra startDate ed endDate
  const allDatesInInterval = [];
  let currentDate = dayjs(startDate);
  while (currentDate.isBefore(dayjs(endDate))) {
    allDatesInInterval.push(currentDate.format("YYYY-MM-DD"));
    currentDate = currentDate.add(1, "day");
  }

  // Trova le date mancanti nell'intervallo
  const missingDates = allDatesInInterval.filter(
    (date) => !existingDates.includes(date),
  );

  // Aggiungi le date mancanti nell'array mantenendo l'ordine
  for (const missingDate of missingDates) {
    let insertIndex = data.findIndex((item) =>
      dayjs(item.date).isAfter(dayjs(missingDate)),
    );
    if (insertIndex === -1) {
      data.push({
        date: missingDate,
        ...Object.fromEntries(fields.map((field) => [field, 0])),
      });
    } else {
      data.splice(insertIndex, 0, {
        date: missingDate,
        ...Object.fromEntries(fields.map((field) => [field, 0])),
      });
    }
  }

  // Ordina l'array per data in ordine crescente
  data.sort((a, b) => (dayjs(a.date).isBefore(dayjs(b.date)) ? -1 : 1));

  const filteredData = data.filter((item) => {
    const itemDate = dayjs(item.date);
    return (
      itemDate.isSame(startDate, "day") ||
      (itemDate.isAfter(startDate, "day") &&
        (itemDate.isSame(endDate, "day") || itemDate.isBefore(endDate, "day")))
    );
  });

  return filteredData;
}
