import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import {
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { getPatient } from "../../actions/patient.js";

import { PropTypes } from "prop-types";
const selector = (state) => state.auth;

Anagrafica.propTypes = {
  patientId: PropTypes.number,
};

function Anagrafica(props) {
  const thisroute = useLocation();
  const { isLoggedIn } = useSelector(selector);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getPatient(props.patientId));
    } else {
      navigate("/form-login");
    }
  }, [isLoggedIn, dispatch, navigate, props.patientId]);

  const { patient } = useSelector(selector);

  return (
    <div
      style={{
        paddingLeft: "32px",
        maxWidth: "calc(100vw - 302px)",
      }}
    >
      {patient ? (
        <Container style={{ padding: 0, margin: 0 }}>
          <Paper style={{ maxWidth: "600px" }}>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <strong>Nome:</strong>
                    </TableCell>
                    <TableCell>{patient.firstName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Cognome:</strong>
                    </TableCell>
                    <TableCell>{patient.lastName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Data di Nascita:</strong>
                    </TableCell>
                    <TableCell>{patient.birthDate}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Sesso:</strong>
                    </TableCell>
                    <TableCell>{patient.gender}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Altezza:</strong>
                    </TableCell>
                    <TableCell>{patient.height}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Codice Fiscale:</strong>
                    </TableCell>
                    <TableCell>{patient.ssn}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Telefono:</strong>
                    </TableCell>
                    <TableCell>{patient.phoneNumber}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Email:</strong>
                    </TableCell>
                    <TableCell>{patient.email}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <strong>Username:</strong>
                    </TableCell>
                    <TableCell>{patient.user.username}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <div
            style={{
              maxWidth: "600px",
              marginTop: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              //startIcon={<PersonAddAltIcon></PersonAddAltIcon>}
              variant="outlined"
              onClick={() => {
                navigate(thisroute.pathname + "/update");
              }}
            >
              AGGIORNA DATI
            </Button>
          </div>
        </Container>
      ) : null}
    </div>
  );
}

export { Anagrafica };
