import { TabContext, TabList } from "@mui/lab";
import {
  Box,
  Button,
  IconButton,
  ButtonGroup,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  Backdrop,
  CircularProgress,
  Badge,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import {
  adjustData,
  getPatientBloodAnalysis,
  getPatientWeights,
  insertBloodAnalysis,
  insertBloodAnalysisThresholds,
  insertPatientWeight,
  updatePatientWeight,
} from "../../actions/patient";
import { useEffect } from "react";
import dayjs from "dayjs";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DataGrid } from "@mui/x-data-grid";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { getBloodAnalysisRange } from "../../actions/patient";
import { PickersDay } from "@mui/x-date-pickers";
import styled from "@emotion/styled";
import { BarChart } from "./BarChart";
import { useSnackbar } from "notistack";
import "dayjs/locale/it";
import { PropTypes } from "prop-types";

const selector = (state) => state.auth;

AnalisiMediche.propTypes = {
  patientBloodAnalysis: PropTypes.array,
  day: PropTypes.object,
  outsideCurrentMonth: PropTypes.bool,
  patientWeight: PropTypes.array,
};

function AnalisiMediche(props) {
  const { patientBloodAnalysis, bloodAnalysisRange, patientWeight } =
    useSelector(selector);
  const [adjustedPatientWeight, setAdjustedPatientWeight] = useState([]);
  const [bloodAnalysisLoading, setBloodAnalysisLoading] = useState(true);
  const [patientWeightLoading, setPatientWeightLoading] = useState(true);
  const [bloodAnalysisRangeLoading, setBloodAnalysisRangeLoading] =
    useState(true);
  const [isBloodAnalsysCreating, setIsBloodAnalsysCreating] = useState(false);
  const dispatch = useDispatch();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isBloodAnalysysThresholdsOpen, setBloodAnalysisThresholdsOpen] =
    useState(false);
  const [isDialogBloodAnalysisOpen, setIsDialogBloodAnalysisOpen] =
    useState(false);
  const [newPatientWeight, setNewPatientWeight] = useState({
    date: null,
    weight: 0,
  });
  const [value2, setValue2] = useState("1");
  const [dateView, setDateView] = useState("day");
  const [dateOne, setDateOne] = useState(dayjs(dayjs().format("YYYY-MM-DD")));
  const [dateTwo, setDateTwo] = useState(dayjs(dayjs().format("YYYY-MM-DD")));
  let { patientId } = useParams();
  patientId = patientId.replace("P", "");
  const myElementRef = useRef(null);
  const emptyAnalysis = {
    eritrociti: null,
    emoglobina: null,
    mcv: null,
    ht: null,
    leucociti: null,
    piastrine: null,
    glicemia: null,
    urea: null,
    na: null,
    k: null,
    creatinina: null,
    colesteroloTotale: null,
    colesteroloHdl: null,
    trigliceridi: null,
    pcr: null,
    albumina: null,
    linfociti: null,
    rdw: null,
    alp: null,
  };
  const thresholds_tabs = {
    eritrociti: ["eritrociti_min", "eritrociti_max"],
    emoglobina: ["emoglobina_min", "emoglobina_max"],
    mcv: ["mcv_min", "mcv_max"],
    ht: ["ht_min", "ht_max"],
    leucociti: ["leucociti_min", "leucociti_max"],
    piastrine: ["piastrine_min", "piastrine_max"],
    glicemia: ["glicemia_min", "glicemia_max"],
    urea: ["urea_min", "urea_max"],
    na: ["na_min", "na_max"],
    k: ["k_min", "k_max"],
    creatinina: ["creatinina_min", "creatinina_max"],
    colesteroloTotale: ["colesterolo_totale_min", "colesterolo_totale_max"],
    colesteroloHdl: ["colesterolo_hdl_min", "colesterolo_hdl_max"],
    trigliceridi: ["trigliceridi_min", "trigliceridi_max"],
    pcr: ["pcr_min", "pcr_max"],
    albumina: ["albumina_min", "albumina_max"],
    linfociti: ["linfociti_min", "linfociti_max"],
    rdw: ["rdw_min", "rdw_max"],
    alp: ["alp_min", "alp_max"],
  };
  const uom = {
    Eritrociti: "x 10^12/L",
    Emoglobina: "g/dL",
    MCV: "fL",
    Ht: "%",
    Leucociti: "x 10^9/L",
    Piastrine: "x 10^9/L",
    Glicemia: "mg/dL",
    Urea: "mg/dL",
    Na: "mmol/L",
    K: "mmol/L",
    Creatinina: "mg/dL",
    Colesterolo_totale: "mg/dL",
    Colesterolo_HDL: "mg/dL",
    Trigliceridi: "mg/dL",
    PCR: "mg/L",
    Albumina: "g/L",
    Linfociti: "%",
    RDW: "%",
    ALP: "U/L",
  };
  const tabs = [
    "eritrociti",
    "emoglobina",
    "mcv",
    "ht",
    "leucociti",
    "piastrine",
    "glicemia",
    "urea",
    "na",
    "k",
    "creatinina",
    "colesteroloTotale",
    "colesteroloHdl",
    "trigliceridi",
    "pcr",
    "albumina",
    "linfociti",
    "rdw",
    "alp",
  ];
  const mapMeasurements = {
    eritrociti: "Eritrociti",
    emoglobina: "Emoglobina",
    mcv: "MCV",
    ht: "Ht",
    leucociti: "Leucociti",
    piastrine: "Piastrine",
    glicemia: "Glicemia",
    urea: "Urea",
    na: "Na",
    k: "K",
    creatinina: "Creatinina",
    colesteroloTotale: "Colesterolo_totale",
    colesteroloHdl: "Colesterolo_HDL",
    trigliceridi: "Trigliceridi",
    pcr: "PCR",
    albumina: "Albumina",
    linfociti: "Linfociti",
    rdw: "RDW",
    alp: "ALP",
  };
  const [bloodAnalysis, setBloodAnalysis] = useState({
    eritrociti: null,
    emoglobina: null,
    mcv: null,
    ht: null,
    leucociti: null,
    piastrine: null,
    glicemia: null,
    urea: null,
    na: null,
    k: null,
    creatinina: null,
    colesteroloTotale: null,
    colesteroloHdl: null,
    trigliceridi: null,
    pcr: null,
    albumina: null,
    linfociti: null,
    rdw: null,
    alp: null,
  });

  const [bloodAnalysisThresholds, setBloodAnalysisThreshold] = useState({
    eritrociti_min: bloodAnalysisRange?.eritrocitiMin || 0,
    eritrociti_max: bloodAnalysisRange?.eritrocitiMax || 0,
    emoglobina_min: bloodAnalysisRange?.emoglobinaMin || 0,
    emoglobina_max: bloodAnalysisRange?.emoglobinaMax || 0,
    mcv_min: bloodAnalysisRange?.mcvMin || 0,
    mcv_max: bloodAnalysisRange?.mcvMax || 0,
    ht_min: bloodAnalysisRange?.htMin || 0,
    ht_max: bloodAnalysisRange?.htMax || 0,
    leucociti_min: bloodAnalysisRange?.leucocitiMin || 0,
    leucociti_max: bloodAnalysisRange?.leucocitiMax || 0,
    piastrine_min: bloodAnalysisRange?.piastrineMin || 0,
    piastrine_max: bloodAnalysisRange?.piastrineMax || 0,
    glicemia_min: bloodAnalysisRange?.glicemiaMin || 0,
    glicemia_max: bloodAnalysisRange?.glicemiaMax || 0,
    urea_min: bloodAnalysisRange?.ureaMin || 0,
    urea_max: bloodAnalysisRange?.ureaMax || 0,
    na_min: bloodAnalysisRange?.naMin || 0,
    na_max: bloodAnalysisRange?.naMax || 0,
    k_min: bloodAnalysisRange?.kMin || 0,
    k_max: bloodAnalysisRange?.kMax || 0,
    creatinina_min: bloodAnalysisRange?.creatininaMin || 0,
    creatinina_max: bloodAnalysisRange?.creatininaMax || 0,
    colesterolo_totale_min: bloodAnalysisRange?.colesterolo_totaleMin || 0,
    colesterolo_totale_max: bloodAnalysisRange?.colesterolo_totaleMax || 0,
    colesterolo_hdl_min: bloodAnalysisRange?.colesterolo_hdlMin || 0,
    colesterolo_hdl_max: bloodAnalysisRange?.colesterolo_hdlMax || 0,
    trigliceridi_min: bloodAnalysisRange?.trigliceridiMin || 0,
    trigliceridi_max: bloodAnalysisRange?.trigliceridiMax || 0,
    pcr_min: bloodAnalysisRange?.pcrMin || 0,
    pcr_max: bloodAnalysisRange?.pcrMax || 0,
    kmin: bloodAnalysisRange?.kmin || 0,
    kmax: bloodAnalysisRange?.kmax || 0,
    albumina_min: bloodAnalysisRange?.albuminaMin || 0,
    albumina_max: bloodAnalysisRange?.albuminaMax || 0,
    linfociti_min: bloodAnalysisRange?.linfocitiMin || 0,
    linfociti_max: bloodAnalysisRange?.linfocitiMax || 0,
    rdw_min: bloodAnalysisRange?.rdwMin || 0,
    rdw_max: bloodAnalysisRange?.rdwMax || 0,
    alp_min: bloodAnalysisRange?.alpMin || 0,
    alp_max: bloodAnalysisRange?.alpMax || 0,
  });

  useEffect(() => {
    if (
      dateView === "day" &&
      dayjs(dateOne).format("YYYY-MM-DD") !== newPatientWeight.date
    ) {
      setNewPatientWeight({
        ...newPatientWeight,
        date: dayjs(dateOne).format("YYYY-MM-DD"),
      });
    }
  }, [dateOne, dateView, newPatientWeight]);

  useEffect(() => {
    setAdjustedPatientWeight(
      adjustData(dateOne, dateTwo, patientWeight, ["weight", "id"]),
    );
  }, [dateOne, dateTwo, patientWeight]);

  useEffect(() => {
    if (patientWeightLoading) {
      setPatientWeightLoading(false);
      dispatch(getPatientWeights(patientId, dateOne, dateTwo));
    }
    if (bloodAnalysisLoading) {
      setBloodAnalysisLoading(false);
      dispatch(getPatientBloodAnalysis(patientId));
    }
    if (bloodAnalysisRangeLoading) {
      setBloodAnalysisRangeLoading(false);
      dispatch(getBloodAnalysisRange(patientId));
    }
  }, [
    patientWeightLoading,
    bloodAnalysisLoading,
    bloodAnalysisRangeLoading,
    dispatch,
    patientId,
    bloodAnalysisRange,
    dateOne,
    dateTwo,
  ]);

  const columns = [
    {
      field: "name",
      headerName: "Tipologia campione-esame",
      width: 250,
    },
    {
      field: "result",
      headerName: "Risultato",
      width: 200,
    },
    {
      field: "uom",
      headerName: "Unità di misura",
      width: 250,
    },
    {
      field: "min",
      headerName: "Valore di riferimento minimo",
      width: 250,
    },
    {
      field: "max",
      headerName: "Valore di riferimento massimo",
      width: 250,
    },
  ];

  const { enqueueSnackbar } = useSnackbar();

  function mapBloodAnalysisRange(patientBloodAnalysis, bloodAnalysisRange) {
    if (bloodAnalysisRange != null && patientBloodAnalysis != null) {
      const patientAnalysis = [...patientBloodAnalysis]
        .filter((value) => value.date === dayjs(dateTwo).format("YYYY-MM-DD"))
        .pop();
      let values = [];
      if (patientAnalysis) {
        tabs.forEach((key) => {
          values.push({
            id: key,
            name: mapMeasurements[key],
            result: patientAnalysis[key],
            min: bloodAnalysisThresholds[thresholds_tabs[key][0]],
            max: bloodAnalysisThresholds[thresholds_tabs[key][1]],
            uom: uom[mapMeasurements[key]],
          });
        });
      }
      return values;
    }
    return [];
  }

  const RedDot = styled("span")({
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "red",
  });

  function serverDay(props) {
    const { patientBloodAnalysis, day, outsideCurrentMonth, ...other } = props;

    const isSelected =
      !props.outsideCurrentMonth &&
      value2 === "1" &&
      patientBloodAnalysis.filter((el) =>
        dayjs(el.date).isSame(dayjs(props.day)),
      ).length > 0;
    return (
      <Badge
        key={props.day.toString()}
        overlap="circular"
        badgeContent={isSelected ? <RedDot /> : undefined}
      >
        <PickersDay
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
        />
      </Badge>
    );
  }

  function serverDayWeight(props) {
    const { patientWeight, day, outsideCurrentMonth, ...other } = props;

    const isSelected =
      !props.outsideCurrentMonth &&
      value2 === "2" &&
      patientWeight
        .filter((el) => dayjs(el.date).isSame(dayjs(props.day)))
        .filter((el) => el.weight !== 0).length > 0;
    return (
      <Badge
        key={props.day.toString()}
        overlap="circular"
        badgeContent={isSelected ? <RedDot /> : undefined}
      >
        <PickersDay
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
        />
      </Badge>
    );
  }

  useEffect(() => {
    setBloodAnalysisThreshold({
      eritrociti_min: bloodAnalysisRange?.eritrocitiMin || 0,
      eritrociti_max: bloodAnalysisRange?.eritrocitiMax || 0,
      emoglobina_min: bloodAnalysisRange?.emoglobinaMin || 0,
      emoglobina_max: bloodAnalysisRange?.emoglobinaMax || 0,
      mcv_min: bloodAnalysisRange?.mcvMin || 0,
      mcv_max: bloodAnalysisRange?.mcvMax || 0,
      ht_min: bloodAnalysisRange?.htMin || 0,
      ht_max: bloodAnalysisRange?.htMax || 0,
      leucociti_min: bloodAnalysisRange?.leucocitiMin || 0,
      leucociti_max: bloodAnalysisRange?.leucocitiMax || 0,
      piastrine_min: bloodAnalysisRange?.piastrineMin || 0,
      piastrine_max: bloodAnalysisRange?.piastrineMax || 0,
      glicemia_min: bloodAnalysisRange?.glicemiaMin || 0,
      glicemia_max: bloodAnalysisRange?.glicemiaMax || 0,
      urea_min: bloodAnalysisRange?.ureaMin || 0,
      urea_max: bloodAnalysisRange?.ureaMax || 0,
      na_min: bloodAnalysisRange?.naMin || 0,
      na_max: bloodAnalysisRange?.naMax || 0,
      k_min: bloodAnalysisRange?.kMin || 0,
      k_max: bloodAnalysisRange?.kMax || 0,
      creatinina_min: bloodAnalysisRange?.creatininaMin || 0,
      creatinina_max: bloodAnalysisRange?.creatininaMax || 0,
      colesterolo_totale_min: bloodAnalysisRange?.colesteroloTotaleMin || 0,
      colesterolo_totale_max: bloodAnalysisRange?.colesteroloTotaleMax || 0,
      colesterolo_hdl_min: bloodAnalysisRange?.colesteroloHdlMin || 0,
      colesterolo_hdl_max: bloodAnalysisRange?.colesteroloHdlMax || 0,
      trigliceridi_min: bloodAnalysisRange?.trigliceridiMin || 0,
      trigliceridi_max: bloodAnalysisRange?.trigliceridiMax || 0,
      pcr_min: bloodAnalysisRange?.pcrMin || 0,
      pcr_max: bloodAnalysisRange?.pcrMax || 0,
      kmin: bloodAnalysisRange?.kmin || 0,
      kmax: bloodAnalysisRange?.kmax || 0,
      albumina_min: bloodAnalysisRange?.albuminaMin || 0,
      albumina_max: bloodAnalysisRange?.albuminaMax || 0,
      linfociti_min: bloodAnalysisRange?.linfocitiMin || 0,
      linfociti_max: bloodAnalysisRange?.linfocitiMax || 0,
      rdw_min: bloodAnalysisRange?.rdwMin || 0,
      rdw_max: bloodAnalysisRange?.rdwMax || 0,
      alp_min: bloodAnalysisRange?.alpMin || 0,
      alp_max: bloodAnalysisRange?.alpMax || 0,
    });
  }, [bloodAnalysisRange]);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isBloodAnalsysCreating}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <TabContext value={value2}>
        <div style={{ paddingLeft: "32px" }}>
          <ButtonGroup size="small" aria-label="small button group">
            <Button
              key="one"
              variant={dateView === "day" ? "contained" : "outlined"}
              onClick={() => {
                setDateView("day");
                setDateOne(dateTwo);
                setBloodAnalysisRangeLoading(true);
                setPatientWeightLoading(true);
              }}
            >
              GIORNO
            </Button>
            <Button
              key="two"
              disabled={value2 === "1"}
              variant={dateView === "week" ? "contained" : "outlined"}
              onClick={() => {
                setDateView("week");
                setDateTwo(dateOne);
                setDateOne(dayjs(dateOne).add(-6, "day"));
                setBloodAnalysisRangeLoading(true);
                setPatientWeightLoading(true);
              }}
            >
              SETTIMANA
            </Button>
          </ButtonGroup>
          <IconButton
            onClick={() => {
              if (dateView === "day") {
                setDateOne(dayjs(dateOne).add(-1, "day"));
                setDateTwo(dayjs(dateOne).add(-1, "day"));
              } else {
                setDateTwo(dayjs(dateTwo).add(-1, "week"));
                setDateOne(dayjs(dateOne).add(-1, "week"));
              }
              setBloodAnalysisRangeLoading(true);
              setPatientWeightLoading(true);
              setBloodAnalysisLoading(true);
            }}
          >
            <ArrowBack></ArrowBack>
          </IconButton>
          <TextField
            sx={{
              display: "content",
              marginRight: "8px",
              textAlign: "center",
            }}
            value={dayjs(dateOne).format("DD-MM-YYYY")}
            disabled
          ></TextField>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
            <DatePicker
              maxDate={dayjs()}
              ref={myElementRef}
              slots={
                value2 === "1"
                  ? {
                      day: serverDay,
                    }
                  : {
                      day: serverDayWeight,
                    }
              }
              slotProps={
                value2 === "1"
                  ? {
                      day: {
                        patientBloodAnalysis,
                      },
                    }
                  : {
                      day: {
                        patientWeight,
                      },
                    }
              }
              format="DD-MM-YYYY"
              sx={{ verticalAlign: "middle" }}
              onChange={(value) => {
                if (dateView === "day") {
                  setDateTwo(value);
                  setDateOne(value);
                } else {
                  setDateTwo(value);
                  setDateOne(dayjs(value).add(-6, "day"));
                }
                setBloodAnalysisRangeLoading(true);
                setBloodAnalysisLoading(true);
                setPatientWeightLoading(true);
              }}
              views={value2 === 2 ? ["day", "week"] : ["day"]}
              value={dateTwo}
            />
          </LocalizationProvider>
          <IconButton
            onClick={() => {
              if (dateView === "day") {
                const newDateOne = dayjs(dateOne).add(1, "day");
                if (newDateOne.isAfter(dayjs())) {
                  setDateOne(dayjs());
                  setDateTwo(dayjs());
                } else {
                  setDateOne(newDateOne);
                  setDateTwo(newDateOne);
                }
              } else {
                const newDateTwo = dayjs(dateTwo).add(6, "day");
                if (newDateTwo.isAfter(dayjs())) {
                  setDateOne(dayjs().subtract(6, "day"));
                  setDateTwo(dayjs());
                } else {
                  setDateTwo(newDateTwo);
                  setDateOne(dayjs(dateOne).add(6, "day"));
                }
              }

              setBloodAnalysisRangeLoading(true);
              setBloodAnalysisLoading(true);
              setPatientWeightLoading(true);
            }}
          >
            <ArrowForward></ArrowForward>
          </IconButton>
        </div>
        <TabList
          style={{ paddingLeft: "32px" }}
          onChange={(event, value) => setValue2(value)}
          aria-label="lab API tabs example"
        >
          <Tab
            onClick={() => {
              setDateView("day");
              setDateOne(dateTwo);
            }}
            label="Analisi del sangue"
            value="1"
          />

          <Tab
            onClick={() => {
              dispatch(getPatientWeights(patientId));
            }}
            label="Peso"
            value="2"
          />
        </TabList>
        <TabPanel value="1">
          <div
            style={{
              height: 500,
              paddingBottom: 50,
              width: "calc(100vw - 302px)",
              paddingLeft: "8px",
            }}
          >
            <Box
              style={{
                textAlign: "left",
                marginBottom: "16px",
              }}
            >
              <Button
                startIcon={<NoteAddIcon></NoteAddIcon>}
                title="aggiungi "
                variant="outlined"
                onClick={() => {
                  setIsDialogBloodAnalysisOpen(true);
                }}
              >
                Aggiungi analisi
              </Button>
              <Button
                sx={{ marginLeft: "8px" }}
                startIcon={<NoteAddIcon></NoteAddIcon>}
                title="soglie "
                variant="outlined"
                onClick={() => {
                  setBloodAnalysisThreshold({
                    eritrociti_min: bloodAnalysisRange?.eritrocitiMin || 0,
                    eritrociti_max: bloodAnalysisRange?.eritrocitiMax || 0,
                    emoglobina_min: bloodAnalysisRange?.emoglobinaMin || 0,
                    emoglobina_max: bloodAnalysisRange?.emoglobinaMax || 0,
                    mcv_min: bloodAnalysisRange?.mcvMin || 0,
                    mcv_max: bloodAnalysisRange?.mcvMax || 0,
                    ht_min: bloodAnalysisRange?.htMin || 0,
                    ht_max: bloodAnalysisRange?.htMax || 0,
                    leucociti_min: bloodAnalysisRange?.leucocitiMin || 0,
                    leucociti_max: bloodAnalysisRange?.leucocitiMax || 0,
                    piastrine_min: bloodAnalysisRange?.piastrineMin || 0,
                    piastrine_max: bloodAnalysisRange?.piastrineMax || 0,
                    glicemia_min: bloodAnalysisRange?.glicemiaMin || 0,
                    glicemia_max: bloodAnalysisRange?.glicemiaMax || 0,
                    urea_min: bloodAnalysisRange?.ureaMin || 0,
                    urea_max: bloodAnalysisRange?.ureaMax || 0,
                    na_min: bloodAnalysisRange?.naMin || 0,
                    na_max: bloodAnalysisRange?.naMax || 0,
                    k_min: bloodAnalysisRange?.kMin || 0,
                    k_max: bloodAnalysisRange?.kMax || 0,
                    creatinina_min: bloodAnalysisRange?.creatininaMin || 0,
                    creatinina_max: bloodAnalysisRange?.creatininaMax || 0,
                    colesterolo_totale_min:
                      bloodAnalysisRange?.colesteroloTotaleMin || 0,
                    colesterolo_totale_max:
                      bloodAnalysisRange?.colesteroloTotaleMax || 0,
                    colesterolo_hdl_min:
                      bloodAnalysisRange?.colesteroloHdlMin || 0,
                    colesterolo_hdl_max:
                      bloodAnalysisRange?.colesteroloHdlMax || 0,
                    trigliceridi_min: bloodAnalysisRange?.trigliceridiMin || 0,
                    trigliceridi_max: bloodAnalysisRange?.trigliceridiMax || 0,
                    pcr_min: bloodAnalysisRange?.pcrMin || 0,
                    pcr_max: bloodAnalysisRange?.pcrMax || 0,
                    kmin: bloodAnalysisRange?.kmin || 0,
                    kmax: bloodAnalysisRange?.kmax || 0,
                    albumina_min: bloodAnalysisRange?.albuminaMin || 0,
                    albumina_max: bloodAnalysisRange?.albuminaMax || 0,
                    linfociti_min: bloodAnalysisRange?.linfocitiMin || 0,
                    linfociti_max: bloodAnalysisRange?.linfocitiMax || 0,
                    rdw_min: bloodAnalysisRange?.rdwMin || 0,
                    rdw_max: bloodAnalysisRange?.rdwMax || 0,
                    alp_min: bloodAnalysisRange?.alpMin || 0,
                    alp_max: bloodAnalysisRange?.alpMax || 0,
                  });
                  setBloodAnalysisThresholdsOpen(true);
                }}
              >
                Soglie
              </Button>
            </Box>

            <Dialog open={isBloodAnalysysThresholdsOpen}>
              <DialogTitle>Valori soglia delle analisi del sangue</DialogTitle>
              <DialogContent>
                {tabs.map((tabname, index) => {
                  return (
                    <>
                      <Grid
                        alignContent={"center"}
                        alignItems={"center"}
                        container
                        spacing={3}
                      >
                        <Grid
                          item
                          xs={6}
                          sx={{ padding: "18px", marginTop: "8px" }}
                        >
                          <TextField
                            // fullWidth
                            fullWidth
                            size="small"
                            id={tabname + "Min"}
                            variant="outlined"
                            defaultValue={
                              bloodAnalysisThresholds[
                                thresholds_tabs[tabname][0]
                              ]
                            }
                            key={tabname + "Min"}
                            label={mapMeasurements[tabname] + " min"}
                            onChange={(e) => {
                              let newBloodAnalysisThresholds = {
                                ...bloodAnalysisThresholds,
                              };
                              newBloodAnalysisThresholds[
                                thresholds_tabs[tabname][0]
                              ] = e.target.value * 1;
                              setBloodAnalysisThreshold(
                                newBloodAnalysisThresholds,
                              );
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sx={{ padding: "18px", marginTop: "8px" }}
                        >
                          <TextField
                            // fullWidth
                            fullWidth
                            size="small"
                            id={tabname + "Max"}
                            variant="outlined"
                            defaultValue={
                              bloodAnalysisThresholds[
                                thresholds_tabs[tabname][1]
                              ]
                            }
                            key={tabname + "Max"}
                            label={mapMeasurements[tabname] + " max"}
                            onChange={(e) => {
                              let newBloodAnalysisThresholds = {
                                ...bloodAnalysisThresholds,
                              };
                              newBloodAnalysisThresholds[
                                thresholds_tabs[tabname][1]
                              ] = e.target.value * 1;
                              setBloodAnalysisThreshold(
                                newBloodAnalysisThresholds,
                              );
                            }}
                          />
                        </Grid>
                      </Grid>
                    </>
                  );
                })}
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    setBloodAnalysisThreshold({
                      eritrociti_min: bloodAnalysisRange?.eritrocitiMin || 0,
                      eritrociti_max: bloodAnalysisRange?.eritrocitiMax || 0,
                      emoglobina_min: bloodAnalysisRange?.emoglobinaMin || 0,
                      emoglobina_max: bloodAnalysisRange?.emoglobinaMax || 0,
                      mcv_min: bloodAnalysisRange?.mcvMin || 0,
                      mcv_max: bloodAnalysisRange?.mcvMax || 0,
                      ht_min: bloodAnalysisRange?.htMin || 0,
                      ht_max: bloodAnalysisRange?.htMax || 0,
                      leucociti_min: bloodAnalysisRange?.leucocitiMin || 0,
                      leucociti_max: bloodAnalysisRange?.leucocitiMax || 0,
                      piastrine_min: bloodAnalysisRange?.piastrineMin || 0,
                      piastrine_max: bloodAnalysisRange?.piastrineMax || 0,
                      glicemia_min: bloodAnalysisRange?.glicemiaMin || 0,
                      glicemia_max: bloodAnalysisRange?.glicemiaMax || 0,
                      urea_min: bloodAnalysisRange?.ureaMin || 0,
                      urea_max: bloodAnalysisRange?.ureaMax || 0,
                      na_min: bloodAnalysisRange?.naMin || 0,
                      na_max: bloodAnalysisRange?.naMax || 0,
                      k_min: bloodAnalysisRange?.kMin || 0,
                      k_max: bloodAnalysisRange?.kMax || 0,
                      creatinina_min: bloodAnalysisRange?.creatininaMin || 0,
                      creatinina_max: bloodAnalysisRange?.creatininaMax || 0,
                      colesterolo_totale_min:
                        bloodAnalysisRange?.colesteroloTotaleMin || 0,
                      colesterolo_totale_max:
                        bloodAnalysisRange?.colesteroloTotaleMax || 0,
                      colesterolo_hdl_min:
                        bloodAnalysisRange?.colesteroloHdlMin || 0,
                      colesterolo_hdl_max:
                        bloodAnalysisRange?.colesteroloHdlMax || 0,
                      trigliceridi_min:
                        bloodAnalysisRange?.trigliceridiMin || 0,
                      trigliceridi_max:
                        bloodAnalysisRange?.trigliceridiMax || 0,
                      pcr_min: bloodAnalysisRange?.pcrMin || 0,
                      pcr_max: bloodAnalysisRange?.pcrMax || 0,
                      kmin: bloodAnalysisRange?.kmin || 0,
                      kmax: bloodAnalysisRange?.kmax || 0,
                      albumina_min: bloodAnalysisRange?.albuminaMin || 0,
                      albumina_max: bloodAnalysisRange?.albuminaMax || 0,
                      linfociti_min: bloodAnalysisRange?.linfocitiMin || 0,
                      linfociti_max: bloodAnalysisRange?.linfocitiMax || 0,
                      rdw_min: bloodAnalysisRange?.rdwMin || 0,
                      rdw_max: bloodAnalysisRange?.rdwMax || 0,
                      alp_min: bloodAnalysisRange?.alpMin || 0,
                      alp_max: bloodAnalysisRange?.alpMax || 0,
                    });
                    setBloodAnalysisThresholdsOpen(false);
                  }}
                >
                  Annulla
                </Button>
                <Button
                  onClick={() => {
                    setIsBloodAnalsysCreating(true);
                    setBloodAnalysisThresholdsOpen(false);
                    dispatch(
                      insertBloodAnalysisThresholds(
                        patientId,
                        bloodAnalysisThresholds,
                      ),
                    );
                    setTimeout(() => {
                      setIsBloodAnalsysCreating(false);
                    }, 2000);
                  }}
                >
                  Conferma
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog open={isDialogBloodAnalysisOpen}>
              <DialogTitle>Analisi del sangue</DialogTitle>
              <DialogContent>
                {tabs.map((tabname, index) => {
                  return (
                    <>
                      <Grid
                        alignContent={"center"}
                        alignItems={"center"}
                        container
                        spacing={4}
                      >
                        <Grid item xs={12} sx={{ padding: "16px" }}>
                          <div style={{ width: "300px", marginTop: "8px" }}>
                            <TextField
                              // fullWidth
                              fullWidth
                              size="small"
                              id={tabname}
                              variant="outlined"
                              value={bloodAnalysis[tabname]}
                              key={tabname}
                              label={tabname}
                              onChange={(e) => {
                                let newbloodAnalysis = { ...bloodAnalysis };
                                newbloodAnalysis[tabname] = e.target.value;
                                setBloodAnalysis(newbloodAnalysis);
                              }}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </>
                  );
                })}
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setIsDialogBloodAnalysisOpen(false)}>
                  Annulla
                </Button>
                <Button
                  onClick={() => {
                    const isAtLeastOneNotNull = Object.values(
                      bloodAnalysis,
                    ).some((value) => value !== null);
                    if (isAtLeastOneNotNull) {
                      // Almeno un parametro è diverso da null, puoi procedere con la conferma
                      setIsBloodAnalsysCreating(true);
                      setIsDialogBloodAnalysisOpen(false);
                      bloodAnalysis.date = dayjs(dateTwo).format("YYYY-MM-DD");
                      setIsBloodAnalsysCreating(false);
                      setTimeout(() => {
                        setIsBloodAnalsysCreating(false);
                        dispatch(
                          insertBloodAnalysis(patientId, bloodAnalysis),
                        ).then(() => {
                          dispatch(getPatientBloodAnalysis(patientId));
                        });
                        enqueueSnackbar("Analisi inserita con successo", {
                          variant: "success",
                        });
                        setBloodAnalysis(emptyAnalysis);
                      }, 2500);
                    } else {
                      // Nessun parametro è diverso da null, gestisci questa situazione come desideri
                      enqueueSnackbar("Inserire almeno un valore", {
                        variant: "error",
                      });
                    }
                  }}
                >
                  Conferma
                </Button>
              </DialogActions>
            </Dialog>

            <DataGrid
              rows={
                patientBloodAnalysis.length > 0 && bloodAnalysisRange != null
                  ? mapBloodAnalysisRange(
                      patientBloodAnalysis,
                      bloodAnalysisRange,
                    )
                  : []
              }
              loading={bloodAnalysisLoading}
              columns={columns}
              disableRowSelectionOnClick
            />
          </div>
        </TabPanel>
        <TabPanel value="2">
          <div
            style={{
              height: 500,
              paddingBottom: 50,
              width: "calc(100vw - 302px)",
              paddingLeft: "8px",
            }}
          >
            <Box
              style={{
                textAlign: "left",
              }}
            >
              <Button
                sx={{ marginBottom: "16px" }}
                startIcon={<NoteAddIcon></NoteAddIcon>}
                title="aggiungi "
                variant="outlined"
                onClick={() => setIsDialogOpen(true)}
                disabled={dateView === "week"}
              >
                Aggiungi misura
              </Button>
            </Box>
            <Dialog open={isDialogOpen}>
              <DialogTitle>Peso (Kg)</DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  type="number"
                  onChange={(e) =>
                    setNewPatientWeight({
                      ...newPatientWeight,
                      weight: e.target.value * 1,
                    })
                  }
                  fullWidth
                  variant="standard"
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setIsDialogOpen(false)}>Annulla</Button>
                <Button
                  onClick={() => {
                    setIsBloodAnalsysCreating(true);
                    setIsDialogOpen(false);
                    setTimeout(() => {
                      if (
                        adjustedPatientWeight.find(
                          (el) => el.date === newPatientWeight.date,
                        ).id !== 0
                      ) {
                        // se esiste già un peso per quella data, aggiorna il peso
                        dispatch(
                          updatePatientWeight(
                            patientId,
                            newPatientWeight,
                            adjustedPatientWeight.find(
                              (el) => el.date === newPatientWeight.date,
                            ).id,
                          ),
                        ).then((_) => {
                          dispatch(
                            getPatientWeights(patientId, dateOne, dateTwo),
                          );
                        });
                        enqueueSnackbar("Peso aggiornato con successo", {
                          variant: "success",
                        });
                      } else {
                        dispatch(
                          insertPatientWeight(patientId, newPatientWeight),
                        ).then((_) => {
                          dispatch(
                            getPatientWeights(patientId, dateOne, dateTwo),
                          );
                        });
                        enqueueSnackbar("Peso inserito con successo", {
                          variant: "success",
                        });
                      }
                      setIsBloodAnalsysCreating(false);
                    }, 2500);
                    setNewPatientWeight({
                      weight: 0,
                      date: dayjs(dateOne).format("YYYY-MM-DD"),
                    });
                  }}
                >
                  Conferma
                </Button>
              </DialogActions>
            </Dialog>

            <BarChart
              data={adjustedPatientWeight.map((item) => {
                return { ...item, Peso: item.weight };
              })}
              keys={["Peso"]}
              maxValue="auto"
              indexBy="date"
              legendX="Data"
              groupMode="grouped"
              legendY="Andamento del peso (Kg)"
              colors={{ scheme: "paired" }}
            ></BarChart>
          </div>
        </TabPanel>
      </TabContext>
    </>
  );
}

export { AnalisiMediche };
