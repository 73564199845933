import * as React from "react";
import { useState } from "react";
import {
  insertPatient,
  updatePatient,
} from "../../services/patient.service.js";
import LoadingButton from "@mui/lab/LoadingButton";
import dayjs from "dayjs";
import {
  Box,
  Checkbox,
  Grid,
  MenuItem,
  FormControl,
  FormControlLabel,
  TextField,
  FormGroup,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { getPatient } from "../../actions/patient.js";

const selector = (state) => state.auth;

function CreatePatient(props) {
  const [yearSelected, setYearSelected] = useState(dayjs().year());
  const [monthSelected, setMonthSelected] = useState(1);
  const years = Array.from(Array(140).keys());
  const months = Array.from(Array(12).keys());
  const [submittingForm, setSubmittingForm] = useState(false);
  const { isLoggedIn } = useSelector(selector);
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const [newPatient, setNewPatient] = useState({
    firstName: { value: "", valid: "" },
    password: { value: "", valid: "" },
    lastName: { value: "", valid: "" },
    phoneNumber: { value: "", valid: "" },
    email: { value: "", valid: "" },
    ssn: { value: "", valid: "" },
    height: { value: "", valid: "" },
    gender: { value: "", valid: "" },
    experimental: { value: true, valid: "" },
    birthDate: {
      day: { value: "" },
      year: { value: "" },
      month: { value: "" },
      valid: "",
    },
    username: { value: "", valid: "" },
  });

  const emptyPatient = {
    firstName: { value: "", valid: "" },
    password: { value: "", valid: "" },
    lastName: { value: "", valid: "" },
    phoneNumber: { value: "", valid: "" },
    email: { value: "", valid: "" },
    ssn: { value: "", valid: "" },
    height: { value: "", valid: "" },
    gender: { value: "MASCHIO", valid: "" },
    experimental: { value: true, valid: "" },
    birthDate: { day: "", year: "", month: "", valid: "" },
    username: { value: "", valid: "" },
  };

  let { patientId } = useParams();
  patientId = patientId ? patientId.replace("P", "") : null;

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/form-login");
    }
    if (patientId) {
      dispatch(getPatient(patientId));
    }
  }, [isLoggedIn, navigate, dispatch, patientId]);

  const { patient } = useSelector(selector);

  useEffect(() => {
    if (patientId && patient) {
      setNewPatient({
        firstName: {
          value: patient.firstName,
        },
        lastName: {
          value: patient.lastName,
        },
        phoneNumber: { value: patient.phoneNumber },
        email: { value: patient.email },
        ssn: { value: patient.ssn },
        height: { value: patient.height },
        gender: { value: patient.gender },
        experimental: { value: patient.experimental },
        birthDate: {
          day: { value: patient.birthDate.split("-")[2] },
          year: { value: patient.birthDate.split("-")[0] },
          month: { value: patient.birthDate.split("-")[1] },
        },
        username: { value: patient.user.username },
      });
    }
  }, [patient, patientId]);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  function selectYear(year) {
    setYearSelected(year);
    setNewPatient({
      ...newPatient,
      birthDate: {
        ...newPatient.birthDate,
        year: { value: year },
      },
    });
  }

  function selectMonth(month) {
    setMonthSelected(month);
    setNewPatient({
      ...newPatient,
      birthDate: {
        ...newPatient.birthDate,
        month: { value: month },
      },
    });
  }

  function handleNewPatientSubmit(e) {
    let valid = true;
    let newPatientCopy = {
      ...newPatient,
    };
    const codiceFiscaleRegex = /^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/;
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const phoneRegex =
      /^(?:(?:\+|00)39)?\s?(?:\d{2,4}\s?[-.]?\s?)?(?:\d{2,4}\s?[-.]?\s?)?\d{2,4}\s?[-.]?\s?\d{2,4}\s?$/;
    const nameRegex = /^[A-Za-z]+(?:\s+[A-Za-z]+)*$/;

    if (
      !newPatientCopy.firstName.value.match(nameRegex) ||
      newPatientCopy.firstName.value.length < 1
    ) {
      newPatientCopy.firstName.valid = false;
      valid = false;
    }
    if (
      !newPatientCopy.lastName.value.match(nameRegex) ||
      newPatientCopy.lastName.value.length < 1
    ) {
      newPatientCopy.lastName.valid = false;
      valid = false;
    }
    if (
      !newPatientCopy.ssn.value.match(codiceFiscaleRegex) ||
      newPatientCopy.ssn.value.length < 16 ||
      newPatientCopy.ssn.value.length > 16
    ) {
      newPatientCopy.ssn.valid = false;
      valid = false;
    }
    if (newPatientCopy.birthDate.year.value.length < 1) {
      newPatientCopy.birthDate.year.valid = false;
      valid = false;
    }
    if (newPatientCopy.birthDate.month.value.length < 1) {
      newPatientCopy.birthDate.month.valid = false;
      valid = false;
    }
    if (newPatientCopy.birthDate.day.value.length < 1) {
      newPatientCopy.birthDate.day.valid = false;
      valid = false;
    }
    if (newPatientCopy.gender.value.length < 1) {
      newPatientCopy.gender.valid = false;
      valid = false;
    }
    if (
      !newPatientCopy.email.value.match(emailRegex) ||
      newPatientCopy.email.value.length < 1
    ) {
      newPatientCopy.email.valid = false;
      valid = false;
    }
    if (
      !newPatientCopy.phoneNumber.value.match(phoneRegex) ||
      newPatientCopy.phoneNumber.value.length < 1
    ) {
      newPatientCopy.phoneNumber.valid = false;
      valid = false;
    }
    if (newPatientCopy.height.value.length < 1) {
      newPatientCopy.height.valid = false;
      valid = false;
    }
    if (
      newPatientCopy.username.value.match('[@&!|"£$%&/()=[\\]]') ||
      newPatientCopy.username.value.length < 8
    ) {
      newPatientCopy.username.valid = false;
      valid = false;
    }
    if (!patientId && newPatientCopy.password.value.length < 8) {
      newPatientCopy.password.valid = false;
      valid = false;
    }

    setNewPatient(newPatientCopy);

    if (valid) {
      setSubmittingForm(true);
      setTimeout(() => {
        if (patientId) {
          updatePatient(
            [newPatient]
              .map((p) => {
                return {
                  firstName: p.firstName.value,
                  lastName: p.lastName.value,
                  height: p.height.value * 1,
                  gender: p.gender.value,
                  ssn: p.ssn.value,
                  birthDate:
                    p.birthDate.year.value +
                    "-" +
                    p.birthDate.month.value +
                    "-" +
                    p.birthDate.day.value,
                  phoneNumber: p.phoneNumber.value,
                  email: p.email.value,
                  experimental: p.experimental.value,
                  username: p.username.value,
                  password: "aa",
                };
              })
              .pop(),
            patientId,
          ).then(
            (result) => {
              setNewPatient(emptyPatient);
              setSubmittingForm(false);
              navigate("/doctor/patients/" + patientId);
              enqueueSnackbar("Paziente aggiornato con successo.", {
                variant: "success",
              });
            },
            (error) => {
              setSubmittingForm(false);
              dispatch({
                type: "ERROR",
                payload: "Errore nell'aggiornamento dei dati del paziente.",
              });
            },
          );
        } else {
          insertPatient(
            [newPatient]
              .map((p) => {
                return {
                  firstName: p.firstName.value,
                  lastName: p.lastName.value,
                  height: p.height.value * 1,
                  gender: p.gender.value,
                  ssn: p.ssn.value,
                  birthDate:
                    p.birthDate.year.value +
                    "-" +
                    p.birthDate.month.value +
                    "-" +
                    p.birthDate.day.value,
                  password: p.password.value,
                  phoneNumber: p.phoneNumber.value,
                  email: p.email.value,
                  experimental: p.experimental.value,
                  username: p.username.value,
                };
              })
              .pop(),
          ).then(
            (result) => {
              setNewPatient(emptyPatient);
              setSubmittingForm(false);
              navigate("/doctor/patients");
              enqueueSnackbar("Paziente creato con successo.", {
                variant: "success",
              });
            },
            (error) => {
              setSubmittingForm(false);
              dispatch({
                type: "ERROR",
                payload: "Errore nella creazione del paziente.",
              });
            },
          );
        }
      }, 2500);
    }
  }

  function FirstNameFormField() {
    return (
      <>
        <FormControl fullWidth>
          <TextField
            size="small"
            error={newPatient.firstName.valid === false}
            label={"Nome"}
            value={patientId ? newPatient?.firstName.value : null}
            helperText={
              newPatient.firstName.valid === false
                ? "Il campo Nome è obbligatorio e deve contenere solo lettere."
                : ""
            }
            id="patientName"
            onChange={(e) => {
              setNewPatient({
                ...newPatient,
                firstName: {
                  value: e.target.value,
                },
              });
            }}
          />
        </FormControl>
      </>
    );
  }

  function LastNameFormField() {
    return (
      <>
        <FormControl required fullWidth>
          <TextField
            size="small"
            error={newPatient.lastName.valid === false}
            helperText={
              newPatient.lastName.valid === false
                ? "Il campo Cognome è obbligatorio e deve contenere solo lettere."
                : ""
            }
            label={"Cognome"}
            value={patientId ? newPatient?.lastName.value : null}
            id="patientLastName"
            onChange={(e) => {
              setNewPatient({
                ...newPatient,
                lastName: {
                  value: e.target.value,
                },
              });
            }}
          />
        </FormControl>
      </>
    );
  }

  function UpdateYearFormField() {
    return (
      <FormControl fullWidth>
        <TextField
          size="small"
          select
          id="year"
          label={"Anno"}
          value={patientId ? newPatient?.birthDate.year.value : null}
          //value={patientId ? patient.birthDate.year : null}
          error={newPatient.birthDate.year.valid === false}
          helperText={
            newPatient.birthDate.year.valid === false
              ? "E' obbligatorio selezionare un Anno valido."
              : ""
          }
          onChange={(e) => {
            selectYear(e.target.value);
          }}
        >
          {years.map((yearbefore) => {
            const year = dayjs().year() - 18 - yearbefore;
            return (
              <MenuItem key={yearbefore} value={year}>
                {year}
              </MenuItem>
            );
          })}
        </TextField>
      </FormControl>
    );
  }

  function UpdateMonthFormField() {
    return (
      <FormControl fullWidth>
        <TextField
          size="small"
          error={newPatient.birthDate.month.valid === false}
          helperText={
            newPatient.birthDate.month.valid === false
              ? "E' obbligatorio selezionare un Mese valido."
              : ""
          }
          id="month"
          select
          label={"Mese"}
          value={patientId ? newPatient?.birthDate.month.value : null}
          onChange={(e) => {
            selectMonth(e.target.value);
          }}
        >
          {months.map((month) => {
            return (
              <MenuItem
                key={month}
                value={month + 1 < 10 ? "0".concat(month + 1) : month + 1}
                id={"m".concat(month)}
              >
                {month + 1 < 10 ? "0".concat(month + 1) : month + 1}
              </MenuItem>
            );
          })}
        </TextField>
      </FormControl>
    );
  }

  function UpdateDayFormField() {
    return (
      <FormControl fullWidth>
        <TextField
          size="small"
          error={newPatient.birthDate.day.valid === false}
          helperText={
            newPatient.birthDate.day.valid === false
              ? "E' obbligatorio selezionare un Giorno valido."
              : ""
          }
          id="day"
          label={"Giorno"}
          value={patientId ? newPatient?.birthDate.day.value : null}
          //value={patientId ? patient.birthDate.day : null}
          select
          onChange={(e) => {
            setNewPatient({
              ...newPatient,
              birthDate: {
                ...newPatient.birthDate,
                day: { value: e.target.value },
              },
            });
          }}
        >
          {Array.from(
            Array(
              dayjs(yearSelected + "-" + monthSelected + "-01").daysInMonth(),
            ).keys(),
          ).map((day) => {
            return (
              <MenuItem
                key={day}
                value={day + 1 < 10 ? "0".concat(day + 1) : day + 1}
              >
                {day + 1 < 10 ? "0".concat(day + 1) : day + 1}
              </MenuItem>
            );
          })}
        </TextField>
      </FormControl>
    );
  }

  function CreateYearFormField() {
    return (
      <FormControl fullWidth>
        <TextField
          size="small"
          select
          id="year"
          label={"Anno"}
          defaultValue={patientId ? newPatient?.birthDate.year.value : null}
          //value={patientId ? patient.birthDate.year : null}
          error={newPatient.birthDate.year.valid === false}
          helperText={
            newPatient.birthDate.year.valid === false
              ? "E' obbligatorio selezionare un Anno valido."
              : ""
          }
          onChange={(e) => {
            selectYear(e.target.value);
          }}
        >
          {years.map((yearbefore) => {
            const year = dayjs().year() - 18 - yearbefore;
            return (
              <MenuItem key={yearbefore} value={year}>
                {year}
              </MenuItem>
            );
          })}
        </TextField>
      </FormControl>
    );
  }

  function CreateMonthFormField() {
    return (
      <FormControl fullWidth>
        <TextField
          size="small"
          error={newPatient.birthDate.month.valid === false}
          helperText={
            newPatient.birthDate.month.valid === false
              ? "E' obbligatorio selezionare un Mese valido."
              : ""
          }
          id="month"
          select
          label={"Mese"}
          defaultValue={patientId ? newPatient?.birthDate.month.value : null}
          onChange={(e) => {
            selectMonth(e.target.value);
          }}
        >
          {months.map((month) => {
            return (
              <MenuItem
                key={month}
                value={month + 1 < 10 ? "0".concat(month + 1) : month + 1}
                id={"m".concat(month)}
              >
                {month + 1 < 10 ? "0".concat(month + 1) : month + 1}
              </MenuItem>
            );
          })}
        </TextField>
      </FormControl>
    );
  }

  function CreateDayFormField() {
    return (
      <FormControl fullWidth>
        <TextField
          size="small"
          error={newPatient.birthDate.day.valid === false}
          helperText={
            newPatient.birthDate.day.valid === false
              ? "E' obbligatorio selezionare un Giorno valido."
              : ""
          }
          id="day"
          label={"Giorno"}
          defaultValue={patientId ? newPatient?.birthDate.day.value : null}
          //value={patientId ? patient.birthDate.day : null}
          select
          onChange={(e) => {
            setNewPatient({
              ...newPatient,
              birthDate: {
                ...newPatient.birthDate,
                day: { value: e.target.value },
              },
            });
          }}
        >
          {Array.from(
            Array(
              dayjs(yearSelected + "-" + monthSelected + "-01").daysInMonth(),
            ).keys(),
          ).map((day) => {
            return (
              <MenuItem
                key={day}
                value={day + 1 < 10 ? "0".concat(day + 1) : day + 1}
              >
                {day + 1 < 10 ? "0".concat(day + 1) : day + 1}
              </MenuItem>
            );
          })}
        </TextField>
      </FormControl>
    );
  }

  function UpdateGenderFormField() {
    return (
      <>
        <FormControl fullWidth>
          <TextField
            size="small"
            error={newPatient.gender.valid === false}
            helperText={
              newPatient.gender.valid === false
                ? "Il campo Sesso è obbligatorio."
                : ""
            }
            select
            fullWidth
            id="gender"
            label={"Gender"}
            value={patientId ? newPatient?.gender.value : null}
            //value={patientId ? patient.gender : null}
            onChange={(e) => {
              setNewPatient({
                ...newPatient,
                gender: { value: e.target.value },
              });
            }}
          >
            {["MALE", "FEMALE"].map((gender) => {
              return (
                <MenuItem value={gender} key={gender}>
                  {gender}
                </MenuItem>
              );
            })}
          </TextField>
        </FormControl>
      </>
    );
  }

  function UpdateHeightFormField() {
    return (
      <>
        <FormControl fullWidth>
          <TextField
            size="small"
            error={newPatient.height.valid === false}
            helperText={
              newPatient.height.valid === false
                ? "Il campo Altezza è obbligatorio."
                : ""
            }
            fullWidth
            id="height"
            select
            label={"Altezza(cm)"}
            value={patientId ? newPatient?.height.value : null}
            //value={patientId ? patient.height : null}
            onChange={(e) => {
              setNewPatient({
                ...newPatient,
                height: { value: e.target.value },
              });
            }}
          >
            {Array.from({ length: 191 }, (_, index) => index + 59).map(
              (height) => {
                return (
                  <MenuItem value={height + 1} key={height}>
                    {height + 1}
                  </MenuItem>
                );
              },
            )}
          </TextField>
        </FormControl>
      </>
    );
  }

  function CreateGenderFormField() {
    return (
      <>
        <FormControl fullWidth>
          <TextField
            size="small"
            error={newPatient.gender.valid === false}
            helperText={
              newPatient.gender.valid === false
                ? "Il campo Sesso è obbligatorio."
                : ""
            }
            select
            fullWidth
            id="gender"
            label={"Gender"}
            defaultValue={patientId ? newPatient?.gender.value : null}
            //value={patientId ? patient.gender : null}
            onChange={(e) => {
              setNewPatient({
                ...newPatient,
                gender: { value: e.target.value },
              });
            }}
          >
            {["MALE", "FEMALE"].map((gender) => {
              return (
                <MenuItem value={gender} key={gender}>
                  {gender}
                </MenuItem>
              );
            })}
          </TextField>
        </FormControl>
      </>
    );
  }

  function CreateHeightFormField() {
    return (
      <>
        <FormControl fullWidth>
          <TextField
            size="small"
            error={newPatient.height.valid === false}
            helperText={
              newPatient.height.valid === false
                ? "Il campo Altezza è obbligatorio."
                : ""
            }
            fullWidth
            id="height"
            select
            label={"Altezza(cm)"}
            defaultValue={patientId ? newPatient?.height.value : null}
            //value={patientId ? patient.height : null}
            onChange={(e) => {
              setNewPatient({
                ...newPatient,
                height: { value: e.target.value },
              });
            }}
          >
            {Array.from({ length: 191 }, (_, index) => index + 59).map(
              (height) => {
                return (
                  <MenuItem value={height + 1} key={height}>
                    {height + 1}
                  </MenuItem>
                );
              },
            )}
          </TextField>
        </FormControl>
      </>
    );
  }

  function SsnFormField() {
    return (
      <>
        <FormControl fullWidth>
          <TextField
            size="small"
            error={newPatient.ssn.valid === false}
            helperText={
              newPatient.ssn.valid === false
                ? "Il campo Codice Fiscale è obbligatorio. Inseriscine uno valido."
                : ""
            }
            onChange={(e) => {
              setNewPatient({
                ...newPatient,
                ssn: {
                  value: e.target.value,
                },
              });
            }}
            fullWidth
            id="ssn"
            label={"Codice fiscale"}
            value={patientId ? newPatient?.ssn.value : null}
          ></TextField>
        </FormControl>
      </>
    );
  }

  function FormRow(formColumns) {
    return (
      <>
        {formColumns.map((column) => {
          return (
            <Grid item key={column} xs={12 / formColumns.length}>
              {column}
            </Grid>
          );
        })}
      </>
    );
  }

  function EmailFormField() {
    return (
      <>
        <FormControl fullWidth>
          <TextField
            size="small"
            error={newPatient.email.valid === false}
            helperText={
              newPatient.email.valid === false
                ? "Il campo Email è obbligatorio. Inseriscine una valida."
                : ""
            }
            onChange={(e) => {
              setNewPatient({
                ...newPatient,
                email: { value: e.target.value },
              });
            }}
            id="useremail"
            label={"Email"}
            value={patientId ? newPatient?.email.value : null}
            type="email"
          ></TextField>
        </FormControl>
      </>
    );
  }

  function PhoneFormField() {
    return (
      <>
        <FormControl fullWidth>
          <TextField
            size="small"
            error={newPatient.phoneNumber.valid === false}
            helperText={
              newPatient.phoneNumber.valid === false
                ? "Il campo Telefono è obbligatorio. Inseriscine uno valido."
                : ""
            }
            onChange={(e) => {
              setNewPatient({
                ...newPatient,
                phoneNumber: { value: e.target.value },
              });
            }}
            id="userphone"
            label={"Telefono"}
            value={patientId ? newPatient?.phoneNumber.value : null}
            type="tel"
          ></TextField>
        </FormControl>
      </>
    );
  }

  function isExperimentalPatientFormField() {
    return (
      <>
        <FormControl fullWidth>
          <FormControlLabel
            control={
              <Checkbox
                checked={newPatient.experimental.value}
                onClick={() =>
                  setNewPatient({
                    ...newPatient,
                    experimental: { value: true },
                  })
                }
                name="experimental"
              />
            }
            label="Sperimentale"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={!newPatient.experimental.value}
                onClick={() =>
                  setNewPatient({
                    ...newPatient,
                    experimental: { value: false },
                  })
                }
                name="experimental"
              />
            }
            label="Controllo"
          />
        </FormControl>
      </>
    );
  }

  function UsernameFormField() {
    return (
      <>
        <FormControl fullWidth>
          <TextField
            size="small"
            error={newPatient.username.valid === false}
            helperText={
              newPatient.username.valid === false
                ? "Il campo Username è obbligatorio. Lunghezza minima 8 caratteri."
                : ""
            }
            onChange={(e) => {
              setNewPatient({
                ...newPatient,
                username: { value: e.target.value },
              });
            }}
            id="username"
            label={"Username"}
            value={patientId ? newPatient?.username.value : null}
            //value={patientId ? patient.username : null}
          ></TextField>
        </FormControl>
      </>
    );
  }

  function PasswordFormField() {
    return (
      <>
        <FormControl fullWidth>
          <TextField
            size="small"
            error={newPatient.password.valid === false}
            helperText={
              newPatient.password.valid === false
                ? "Il campo Password è obbligatorio. Lunghezza minima 8 caratteri."
                : ""
            }
            onChange={(e) => {
              setNewPatient({
                ...newPatient,
                password: { value: e.target.value },
              });
            }}
            id="password"
            type={showPassword ? "text" : "password"}
            label={"Password"}
            //value={patientId ? patient.password : null}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleTogglePassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          ></TextField>
        </FormControl>
      </>
    );
  }

  return (
    <Box
      component="form"
      sx={{ m: 1, marginLeft: "32px", marginRight: "32px" }}
      noValidate
      autoComplete="off"
      paddingTop="8px"
    >
      <Box sx={{ flexGrow: 1 }}>
        <FormGroup>
          <Grid container spacing={1}>
            <Grid container item spacing={2} style={{ marginBottom: "24px" }}>
              {FormRow([FirstNameFormField(), LastNameFormField()])}
            </Grid>
            <Grid container item spacing={2} style={{ marginBottom: "24px" }}>
              {!patientId
                ? FormRow([
                    CreateYearFormField(),
                    CreateMonthFormField(),
                    CreateDayFormField(),
                  ])
                : FormRow([
                    UpdateYearFormField(),
                    UpdateMonthFormField(),
                    UpdateDayFormField(),
                  ])}
            </Grid>
            <Grid container item spacing={2} style={{ marginBottom: "24px" }}>
              {!patientId
                ? FormRow([CreateGenderFormField(), CreateHeightFormField()])
                : FormRow([UpdateGenderFormField(), UpdateHeightFormField()])}
            </Grid>
            <Grid container item spacing={2} style={{ marginBottom: "24px" }}>
              {FormRow([SsnFormField(), isExperimentalPatientFormField()])}
            </Grid>
            <Grid container item spacing={2} style={{ marginBottom: "24px" }}>
              {FormRow([EmailFormField(), PhoneFormField()])}
            </Grid>
            <Grid container item spacing={2} style={{ marginBottom: "24px" }}>
              {patientId
                ? FormRow([UsernameFormField()])
                : FormRow([UsernameFormField(), PasswordFormField()])}
            </Grid>
          </Grid>
        </FormGroup>
      </Box>
      <LoadingButton
        loading={submittingForm}
        onClick={(e) => handleNewPatientSubmit(e)}
        style={{ marginTop: "18px" }}
        variant="contained"
      >
        {" "}
        {patientId ? "Aggiorna Dati" : "Crea paziente"}
      </LoadingButton>
    </Box>
  );
}

export { CreatePatient };
