import { useDispatch, useSelector } from "react-redux";
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getQuestionnaireTemplate } from "../../actions/questionnaire";
import { Fragment } from "react";

const selector = (state) => state.auth;

function QuestionnaireTemplate(props) {
  const dispatch = useDispatch();
  var { questionnaire, isLoggedIn } = useSelector(selector);
  const [questionnaireLoading, setQuestionnaireLoading] = useState(true);
  const navigate = useNavigate();
  const { questionnaireId } = useParams();

  useEffect(() => {
    if (isLoggedIn) {
      if (questionnaireLoading) {
        dispatch(getQuestionnaireTemplate(questionnaireId));
        setQuestionnaireLoading(false);
      }
    } else {
      navigate("/form-login");
    }
  }, [questionnaireLoading, dispatch, navigate, questionnaireId, isLoggedIn]);

  // Raggruppare le domande per sezione
  const questionsBySection = {};
  questionnaire?.questions?.forEach((question) => {
    const sectionId = question.questionSection.id;
    if (!questionsBySection[sectionId]) {
      questionsBySection[sectionId] = {
        sectionName: question.questionSection.name,
        questions: [],
      };
    }
    questionsBySection[sectionId].questions.push(question);
  });

  return (
    <div style={{ paddingTop: 16 }}>
      <Paper sx={{ width: "calc(100vw - 302px)", marginLeft: "32px" }}>
        <TableContainer sx={{ maxHeight: 550 }}>
          <Table stickyHeader aria-label="sticky table">
            <caption>
              Nome: {questionnaire?.name}
              <br />
              <br />
              Punteggio:{" "}
              {questionnaire?.name !==
              "INDICE PROGNOSTICO MULTIDIMENSIONALE (MPI)"
                ? `${questionnaire?.maxScore}`
                : `1`}
            </caption>
            <TableHead>
              <TableRow>
                <TableCell>Domanda</TableCell>
                <TableCell>Descrizione</TableCell>
                <TableCell>Risposte</TableCell>
                <TableCell>Punti</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.values(questionsBySection).map((section) => (
                <Fragment key={section.sectionName}>
                  <TableRow>
                    <TableCell colSpan={5} style={{ fontWeight: "bold" }}>
                      {section.sectionName}
                    </TableCell>
                  </TableRow>
                  {section.questions.map((question) => (
                    <TableRow key={question.id}>
                      <TableCell>
                        {question.text.replace("undefined", "")}
                      </TableCell>
                      <TableCell>
                        {question.description.replace("undefined", "")}
                      </TableCell>
                      <TableCell>
                        {question.possibleQuestionAnswer
                          .sort((a, b) => a.id - b.id)
                          .map((answer) => (
                            <p key={answer.id}>{answer.text}</p>
                          ))}
                      </TableCell>
                      <TableCell>
                        {question.possibleQuestionAnswer.map((answer) => (
                          <p key={answer.id}>{answer.score || 0}</p>
                        ))}
                      </TableCell>
                    </TableRow>
                  ))}
                </Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

export { QuestionnaireTemplate };
