import authServices from "../services/auth.services";
import { menu_actions } from "../components/sidebar_actions";
import { getDoctors } from "./doctor";
import { getPatients } from "./patient";

export const login = (username, password) => (dispatch) => {
  return authServices.login(username, password).then(
    (data) => {
      if (!data.tokenExists) {
        dispatch({
          type: "ACTIVE_CONTENT",
          payload: "FITBIT_LOGIN",
          show: false,
        });
      }
      dispatch({
        type: "LOGIN_SUCCESS",
        payload: { user: data },
      });
      dispatch({
        type: "MENU",
        payload: menu_actions,
      });
      localStorage.setItem("menu", JSON.stringify(menu_actions));
      if (data.authority === "ROLE_ADMIN") {
        dispatch(getDoctors());
      } else if (data.authority === "ROLE_DOCTOR") {
        dispatch(getPatients());
      }
      return Promise.resolve();
    },
    (error) => {
      dispatch({
        type: "ERROR",
        payload: "Credenziali errate",
      });
      return error;
    },
  );
};

export const logout = () => (dispatch) => {
  return authServices.logout().then(
    (data) => {
      dispatch({
        type: "LOGOUT",
        payload: null,
      });
      dispatch({
        type: "USER_PROFILE",
        payload: -1,
      });
      return;
    },
    (error) => {
      return Promise.reject();
    },
  );
};

export const userDetail = (content) => (dispatch) => {
  dispatch({
    type: "USER_PROFILE",
    payload: content,
  });
};

/*
export const getPatientQuestionnaires = (id) => (dispatch) => {
  return authServices.getPatientQuestionnaires(id).then((data) => {
    dispatch({
      type: "PATIENT_QUESTIONNAIRES",
      payload: data.data,
    });
  });
};

 */
