import { Alert } from "@mui/material";

const ErrorFitbit = () => {
  return (
    <>
      <div
        style={{
          height: 500,
          width: "calc(100vw - 302px)",
          marginLeft: "32px",
        }}
      >
        <Alert sx={{ width: "fitContent" }} severity="error">
          Errore durante il completamento della registrazione su HealthApp.
          Riprovare.
        </Alert>
      </div>
    </>
  );
};

export default ErrorFitbit;
