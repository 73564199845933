import React, { useState } from "react";
import {
  Paper,
  Typography,
  TextField,
  Button,
  Grid,
  Box,
  IconButton,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import { createQuestionnaire } from "../../actions/questionnaire";
import { useDispatch } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";

function CreateQuestionnaire() {
  const initialOption = {
    text: "",
    score: 0,
  };

  const initialQuestion = {
    text: "",
    description: "",
    options: [{ ...initialOption }],
  };

  const initialSection = {
    title: "",
    questions: [{ ...initialQuestion }],
  };

  const [sections, setSections] = useState([initialSection]);
  const [title, setTitle] = useState("");
  const [isFormValid, setIsFormValid] = useState(true);
  const [createQuestionnaireLoading, setCreateQuestionnaireLoading] =
    useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const handleAddSection = () => {
    setSections([...sections, { ...initialSection }]);
    setIsFormValid(true);
    setCreateQuestionnaireLoading(false);
  };

  const handleRemoveSection = (sectionIndex) => {
    const updatedSections = [...sections];
    updatedSections.splice(sectionIndex, 1);
    setSections(updatedSections);
    setIsFormValid(true);
    setCreateQuestionnaireLoading(false);
  };

  const handleSectionTitleChange = (sectionIndex, value) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].title = value;
    setSections(updatedSections);
    setIsFormValid(true);
    setCreateQuestionnaireLoading(false);
  };

  const handleAddQuestion = (sectionIndex) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].questions.push({ ...initialQuestion });
    setSections(updatedSections);
    setIsFormValid(true);
    setCreateQuestionnaireLoading(false);
  };

  const handleRemoveQuestion = (sectionIndex, questionIndex) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].questions.splice(questionIndex, 1);
    setSections(updatedSections);
    setIsFormValid(true);
    setCreateQuestionnaireLoading(false);
  };

  const handleQuestionChange = (sectionIndex, questionIndex, field, value) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].questions[questionIndex][field] = value;
    setSections(updatedSections);
    setIsFormValid(true);
    setCreateQuestionnaireLoading(false);
  };

  const handleAddOption = (sectionIndex, questionIndex) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].questions[questionIndex].options.push({
      ...initialOption,
    });
    setSections(updatedSections);
    setIsFormValid(true);
    setCreateQuestionnaireLoading(false);
  };

  const handleRemoveOption = (sectionIndex, questionIndex, optionIndex) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].questions[questionIndex].options.splice(
      optionIndex,
      1,
    );
    setSections(updatedSections);
    setIsFormValid(true);
    setCreateQuestionnaireLoading(false);
  };

  const handleOptionChange = (
    sectionIndex,
    questionIndex,
    optionIndex,
    field,
    value,
  ) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].questions[questionIndex].options[optionIndex][
      field
    ] = value;
    setSections(updatedSections);
    setIsFormValid(true);
    setCreateQuestionnaireLoading(false);
  };

  const handleTitleChange = (value) => {
    setTitle(value);
    setIsFormValid(true);
    setCreateQuestionnaireLoading(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setCreateQuestionnaireLoading(true);

    const isTitleEmpty = title.trim() === "";
    const isAnySectionEmpty = sections.some(
      (section) => section.title.trim() === "",
    );
    const isAnyQuestionEmpty = sections.some((section) =>
      section.questions.some((question) => question.text.trim() === ""),
    );
    const isAnyOptionEmpty = sections.some((section) =>
      section.questions.some((question) =>
        question.options.some((option) => option.text.trim() === ""),
      ),
    );

    const sectionNamesSet = new Set();
    let hasDuplicateSectionName = false;
    let hasEmptySection = false;

    sections.forEach((section, sectionIndex) => {
      const sectionName = section.title.trim();

      // Verifica se il nome della sezione è vuoto
      if (!sectionName) {
        hasEmptySection = true;
        return;
      }

      // Verifica se il nome della sezione è già stato utilizzato
      if (sectionNamesSet.has(sectionName)) {
        hasDuplicateSectionName = true;
      } else {
        sectionNamesSet.add(sectionName);
      }

      // Verifica se la sezione ha almeno una domanda
      if (section.questions.length === 0) {
        hasEmptySection = true;
      } else {
        let hasEmptyQuestion = false;
        section.questions.forEach((question, questionIndex) => {
          // Verifica se la domanda ha almeno una risposta
          if (question.options.length === 0) {
            hasEmptyQuestion = true;
          }
        });
        if (hasEmptyQuestion) {
          hasEmptySection = true;
        }
      }
    });

    if (
      isTitleEmpty ||
      isAnySectionEmpty ||
      isAnyQuestionEmpty ||
      isAnyOptionEmpty ||
      hasDuplicateSectionName ||
      hasEmptySection
    ) {
      setIsFormValid(false);
    } else {
      setIsFormValid(true);
      let newMaxScore = 0;
      sections.forEach((section) => {
        section.questions.forEach((question) => {
          let questionMaxScore = 0;
          question.options.forEach((option) => {
            const score = parseFloat(option.score);
            if (!isNaN(score) && score > questionMaxScore) {
              questionMaxScore = score;
            }
          });
          newMaxScore += questionMaxScore;
        });
      });
      const questionnaireTemplate = {
        name: title, // Il titolo del questionario
        questions: sections.flatMap((section) =>
          section.questions.map((question) => ({
            text: question.text, // Testo della domanda
            questionSectionName: section.title, // Nome della sezione a cui appartiene la domanda
            description:
              question.description !== "" ? question.description : "", // Descrizione della domanda
            possibleQuestionAnswer: question.options.map((option) => ({
              text: option.text, // Testo della risposta
              score: option.score, // Punteggio associato alla risposta
            })),
          })),
        ),
        max_score: newMaxScore, // Punteggio massimo (puoi personalizzarlo)
      };
      dispatch(createQuestionnaire(questionnaireTemplate))
        .then((result) => {
          setCreateQuestionnaireLoading(false);
          navigate("/admin/questionnaires");
          enqueueSnackbar("Questionario creato con successo.", {
            variant: "success",
          });
        })
        .catch((error) => {
          // Gestione dell'errore
          setCreateQuestionnaireLoading(false);
          dispatch({
            type: "ERROR",
            payload: "Errore nella creazione del questionario.",
          });
        });
    }
  };

  return (
    <div
      style={{
        margin: "20px",
        width: "calc(100vw - 302px)",
        marginTop: "0px",
        paddingTop: 16,
      }}
    >
      <Paper elevation={3} style={{ padding: "32px" }}>
        <Typography variant="h5" gutterBottom>
          Creazione di un nuovo questionario
        </Typography>
        <TextField
          size="small"
          label="Titolo del questionario"
          variant="outlined"
          fullWidth
          required
          value={title}
          onChange={(e) => handleTitleChange(e.target.value)}
          margin="normal"
          error={!isFormValid && title.trim() === ""}
          helperText={
            !isFormValid && title.trim() === ""
              ? "Il nome del questionario è obbligatorio"
              : ""
          }
        />
        {sections.map((section, sectionIndex) => (
          <Box
            key={sectionIndex}
            mt={3}
            p={3}
            border={1}
            borderColor="grey.300"
          >
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6}>
                <Typography variant="h6">Sezione {sectionIndex + 1}</Typography>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "right" }}>
                <IconButton
                  color="error"
                  onClick={() => handleRemoveSection(sectionIndex)}
                >
                  <Delete />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <TextField
                size="small"
                required
                label={`Nome della sezione ${sectionIndex + 1}`}
                variant="outlined"
                fullWidth
                value={section.title}
                onChange={(e) =>
                  handleSectionTitleChange(sectionIndex, e.target.value)
                }
                margin="normal"
                error={
                  (!isFormValid && section.title.trim() === "") ||
                  sections.find(
                    (s, index) =>
                      s.title === section.title &&
                      index !== sectionIndex &&
                      s.title !== "",
                  ) !== undefined ||
                  section.questions.length === 0
                }
                helperText={
                  !isFormValid && section.title.trim() === ""
                    ? "Il nome della sezione è obbligatorio"
                    : sections.find(
                          (s, index) =>
                            s.title === section.title &&
                            index !== sectionIndex &&
                            s.title !== "",
                        ) !== undefined
                      ? "Il nome della sezione deve essere univoco"
                      : section.questions.length === 0
                        ? "La sezione deve avere almeno una domanda"
                        : ""
                }
              />
            </Grid>
            {section.questions.map((question, questionIndex) => (
              <Box key={questionIndex} mt={2}>
                <Grid container spacing={2} alignItems="center">
                  <Grid item xs={6}>
                    <Typography variant="subtitle1">
                      Domanda {questionIndex + 1}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: "right" }}>
                    <IconButton
                      color="error"
                      onClick={() =>
                        handleRemoveQuestion(sectionIndex, questionIndex)
                      }
                    >
                      <Delete />
                    </IconButton>
                  </Grid>
                </Grid>
                <TextField
                  size="small"
                  label={`Testo della domanda`}
                  variant="outlined"
                  fullWidth
                  required
                  value={question.text}
                  onChange={(e) =>
                    handleQuestionChange(
                      sectionIndex,
                      questionIndex,
                      "text",
                      e.target.value,
                    )
                  }
                  margin="normal"
                  error={
                    (!isFormValid && question.text.trim() === "") ||
                    question.options.length === 0
                  }
                  helperText={
                    !isFormValid && question.text.trim() === ""
                      ? "Il testo della domanda è obbligatorio"
                      : question.options.length === 0
                        ? "La domanda deve avere almeno una risposta"
                        : ""
                  }
                />
                <TextField
                  size="small"
                  label={`Descrizione della domanda`}
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={2}
                  value={question.description}
                  onChange={(e) =>
                    handleQuestionChange(
                      sectionIndex,
                      questionIndex,
                      "description",
                      e.target.value,
                    )
                  }
                  margin="normal"
                />
                {question.options.map((option, optionIndex) => (
                  <Box key={optionIndex} mt={1} display="flex">
                    <Grid container spacing={3} alignItems="center">
                      <Grid item xs={6}>
                        <TextField
                          size="small"
                          label={`Risposta ${optionIndex + 1}`}
                          variant="outlined"
                          fullWidth
                          required
                          value={option.text}
                          onChange={(e) =>
                            handleOptionChange(
                              sectionIndex,
                              questionIndex,
                              optionIndex,
                              "text",
                              e.target.value,
                            )
                          }
                          margin="normal"
                          error={!isFormValid && option.text.trim() === ""}
                          helperText={
                            !isFormValid && option.text.trim() === ""
                              ? "Il testo della risposta è obbligatorio"
                              : ""
                          }
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <TextField
                          size="small"
                          label="Punteggio"
                          variant="outlined"
                          type="number"
                          fullWidth
                          required
                          value={option.score}
                          onChange={(e) =>
                            handleOptionChange(
                              sectionIndex,
                              questionIndex,
                              optionIndex,
                              "score",
                              e.target.value,
                            )
                          }
                          margin="normal"
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton
                          color="error"
                          onClick={() =>
                            handleRemoveOption(
                              sectionIndex,
                              questionIndex,
                              optionIndex,
                            )
                          }
                        >
                          <Delete />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Box>
                ))}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleAddOption(sectionIndex, questionIndex)}
                  style={{ marginTop: "10px" }}
                >
                  Aggiungi Risposta
                </Button>
              </Box>
            ))}
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleAddQuestion(sectionIndex)}
              style={{ marginTop: "20px" }}
            >
              Aggiungi Domanda
            </Button>
          </Box>
        ))}
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddSection}
          style={{ marginTop: "20px" }}
        >
          Aggiungi Sezione
        </Button>
        <LoadingButton
          variant="contained"
          loading={createQuestionnaireLoading}
          onClick={handleSubmit}
          style={{ marginTop: "20px", float: "right" }}
        >
          Crea Questionario
        </LoadingButton>
      </Paper>
    </div>
  );
}

export { CreateQuestionnaire };
