import axios from "axios";

const getDoctors = () => {
  return axios.get("/api/doctors", null);
};

export const insertDoctor = (doctor) => {
  return axios.post("/api/doctors", doctor, {}).then((response) => {
    if (response.data) {
      console.log(response.data);
    }
  });
};
const deleteDoctor = (doctorId) => {
  return axios.delete("/api/doctors/" + doctorId);
};

const doctorServices = {
  getDoctors,
  insertDoctor,
  deleteDoctor,
};
export default doctorServices;
