import React, { useState } from "react";
import { TextField, Box, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { savePassword } from "../../services/auth.services.js";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import logo from "../../icons/daily-health-app.png";
import { InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const ChangePasswordForm = () => {
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [newPassword, setNewPassword] = useState({
    password: { value: "", valid: "" },
    confirmPassword: { value: "", valid: "" },
  });

  const handleToggleNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  var token = useParams();

  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    let newPasswordCopy = {
      ...newPassword,
    };

    if (newPasswordCopy.password.value.length < 8) {
      newPasswordCopy.password.valid = false;
    } else {
      newPasswordCopy.password.valid = true;
    }

    if (newPasswordCopy.confirmPassword.value.length < 8) {
      newPasswordCopy.confirmPassword.valid = false;
    } else {
      newPasswordCopy.confirmPassword.valid = true;
    }

    setNewPassword(newPasswordCopy);

    if (
      newPassword.password.value === newPassword.confirmPassword.value &&
      newPassword.password.valid &&
      newPassword.confirmPassword.valid
    ) {
      setPasswordsMatch(true);
      setLoading(true);
      var json = {
        new_password: newPassword.password.value,
        token: token.token,
      };
      setTimeout(
        () =>
          savePassword(json).then(
            (result) => {
              if (result.data.password_changed !== false) {
                setLoading(false);
                navigate("/form-login");
                enqueueSnackbar("Password modificata con successo", {
                  variant: "success",
                });
              } else {
                setLoading(false);
                enqueueSnackbar("Errore durante il reset della password", {
                  variant: "error",
                });
              }
            },
            (error) => {
              setLoading(false);
              dispatch({
                type: "ERROR",
                payload: "Errore durante il reset della password",
              });
            },
          ),
        2500,
      );
    } else {
      if (newPassword.password.value !== newPassword.confirmPassword.value)
        setPasswordsMatch(false);
    }
  };

  return (
    <Box
      style={{
        width: "100vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "60vh",
      }}
    >
      <Grid
        container
        spacing={3}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "50vh" }}
      >
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <h2>VERONA EXPERIMENT</h2>
          <img src={logo} width={"60px"} alt="" />
          <h2>CAMBIO PASSWORD</h2>
          <TextField
            style={{ width: 200 }}
            size="small"
            required
            type={showNewPassword ? "text" : "password"}
            label="Nuova Password"
            // value={newPassword.password.value}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleToggleNewPassword}
                    edge="end"
                  >
                    {showNewPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setNewPassword({
                ...newPassword,
                password: {
                  value: e.target.value,
                },
              });
              if (e.target.value === newPassword.confirmPassword.value)
                setPasswordsMatch(true);
              else setPasswordsMatch(false);
            }}
            error={newPassword.password.valid === false}
            helperText={
              newPassword.password.valid === false
                ? "La password deve contenere almeno 8 caratteri"
                : ""
            }
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <TextField
            style={{ width: 200 }}
            size="small"
            required
            type={showConfirmPassword ? "text" : "password"}
            label="Conferma Password"
            // value={newPassword.confirmPassword.value}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleToggleConfirmPassword}
                    edge="end"
                  >
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setNewPassword({
                ...newPassword,
                confirmPassword: {
                  value: e.target.value,
                },
              });
              if (e.target.value === newPassword.password.value)
                setPasswordsMatch(true);
              else setPasswordsMatch(false);
            }}
            error={
              newPassword.confirmPassword.valid === false || !passwordsMatch
            }
            helperText={
              newPassword.confirmPassword.valid === false
                ? "La password deve contenere almeno 8 caratteri"
                : !passwordsMatch
                  ? "Le password non corrispondono"
                  : ""
            }
          />
        </Grid>
        <Grid item xs={12}>
          <LoadingButton
            loading={loading}
            variant="outlined"
            onClick={(e) => handleSubmit(e)}
          >
            Reimposta Password
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export { ChangePasswordForm };
