import { RiHealthBookLine, RiSurveyLine } from "react-icons/ri";
import { IoIosPeople } from "react-icons/io";
const DOCTOR = 0;
const PATIENT = 1;
const QUESTIONNAIRE = 2;
const HEALTH = 3;
const PROFILE = 4;
const COMPILATION = 5;
const PATIENT_DETAIL = 6;
const HEALTH_ICON = <RiHealthBookLine />;
const QUESTIONNAIRE_ICON = <RiSurveyLine />;
const PATIENTS_ICON = <IoIosPeople />;

export {
  PATIENTS_ICON,
  HEALTH_ICON,
  QUESTIONNAIRE_ICON,
  DOCTOR,
  PATIENT,
  QUESTIONNAIRE,
  HEALTH,
  PROFILE,
  COMPILATION,
  PATIENT_DETAIL,
};
