import { useState } from "react";
import {
  TextField,
  Box,
  Grid,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import logo from "../../icons/daily-health-app.png";
import { useSnackbar } from "notistack";
import { useDispatch } from "react-redux";
import { resetPassword } from "../../../src/services/auth.services.js";

const ResetPasswordPage = () => {
  const [username, setUsername] = useState("");
  const [selectedRole, setSelectedRole] = useState("PATIENT"); // Ruolo predefinito: paziente

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    setTimeout(
      () =>
        resetPassword(username, selectedRole).then(
          (result) => {
            setLoading(false);
            navigate("/form-login");
            enqueueSnackbar(
              "Abbiamo inviato una email con le istruzioni per recuperare la password",
              {
                variant: "success",
              },
            );
          },
          (error) => {
            setLoading(false);
            dispatch({
              type: "ERROR",
              payload: "Errore durante il reset della password",
            });
          },
        ),
      2500,
    );
  };

  return (
    <Box
      style={{
        width: "100vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "60vh",
      }}
    >
      <Grid
        container
        spacing={3}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "50vh" }}
      >
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <h2>VERONA EXPERIMENT</h2>
          <img src={logo} width={"60px"} alt="" />
          <h2>RESET PASSWORD</h2>
          <TextField
            style={{ width: 200 }}
            size="small"
            required
            onChange={(event) => setUsername(event.target.value)}
            label="Username"
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <FormControl component="fieldset">
            <RadioGroup
              row
              name="role"
              value={selectedRole}
              style={{ paddingLeft: "15px" }}
              onChange={(event) => setSelectedRole(event.target.value)}
            >
              <FormControlLabel
                value="PATIENT"
                control={<Radio />}
                label="Paziente"
              />
              <FormControlLabel
                value="DOCTOR"
                control={<Radio />}
                label="Dottore"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} style={{ textAlign: "center" }}>
          <LoadingButton
            variant="outlined"
            loading={loading}
            onClick={(e) => handleSubmit(e)}
          >
            Invia email di reset password
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export { ResetPasswordPage };
