import * as React from "react";
import { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Grid,
  FormControl,
  TextField,
  FormGroup,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { insertDoctor } from "../../services/doctor.services.js";

const selector = (state) => state.auth;

function CreateDoctor(props) {
  const [submittingForm, setSubmittingForm] = useState(false);
  const { isLoggedIn } = useSelector(selector);
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const [newDoctor, setNewDoctor] = useState({
    firstName: { value: "", valid: "" },
    password: { value: "", valid: "" },
    lastName: { value: "", valid: "" },
    email: { value: "", valid: "" },
    ssn: { value: "", valid: "" },
    username: { value: "", valid: "" },
  });

  const emptyDoctor = {
    firstName: { value: "", valid: "" },
    password: { value: "", valid: "" },
    lastName: { value: "", valid: "" },
    email: { value: "", valid: "" },
    ssn: { value: "", valid: "" },
    username: { value: "", valid: "" },
  };

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/form-login");
    }
  }, [isLoggedIn, navigate]);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  function handleNewDoctorSubmit(e) {
    let valid = true;
    let newDoctorCopy = {
      ...newDoctor,
    };
    const codiceFiscaleRegex = /^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/;
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const nameRegex = /^[A-Za-z]+(?:\s+[A-Za-z]+)*$/;

    if (
      !newDoctorCopy.firstName.value.match(nameRegex) ||
      newDoctorCopy.firstName.value.length < 1
    ) {
      newDoctorCopy.firstName.valid = false;
      valid = false;
    }
    if (
      !newDoctorCopy.lastName.value.match(nameRegex) ||
      newDoctorCopy.lastName.value.length < 1
    ) {
      newDoctorCopy.lastName.valid = false;
      valid = false;
    }
    if (
      !newDoctorCopy.ssn.value.match(codiceFiscaleRegex) ||
      newDoctorCopy.ssn.value.length < 16 ||
      newDoctorCopy.ssn.value.length > 16
    ) {
      newDoctorCopy.ssn.valid = false;
      valid = false;
    }
    if (
      !newDoctorCopy.email.value.match(emailRegex) ||
      newDoctorCopy.email.value.length < 1
    ) {
      newDoctorCopy.email.valid = false;
      valid = false;
    }
    if (
      newDoctorCopy.username.value.match('[@&!|"£$%&/()=[\\]]') ||
      newDoctorCopy.username.value.length < 8
    ) {
      newDoctorCopy.username.valid = false;
      valid = false;
    }
    if (newDoctorCopy.password.value.length < 8) {
      newDoctorCopy.password.valid = false;
      valid = false;
    }

    setNewDoctor(newDoctorCopy);

    if (valid) {
      setSubmittingForm(true);
      setTimeout(
        () =>
          insertDoctor(
            [newDoctor]
              .map((d) => {
                return {
                  firstName: d.firstName.value,
                  lastName: d.lastName.value,
                  ssn: d.ssn.value,
                  password: d.password.value,
                  email: d.email.value,
                  username: d.username.value,
                };
              })
              .pop(),
          ).then(
            (result) => {
              setNewDoctor(emptyDoctor);
              setSubmittingForm(false);
              navigate("/admin/doctors");
              enqueueSnackbar("Dottore creato con successo.", {
                variant: "success",
              });
            },
            (error) => {
              setSubmittingForm(false);
              dispatch({
                type: "ERROR",
                payload: "Errore nella creazione del dottore.",
              });
            },
          ),
        2500,
      );
    }
  }

  function FirstNameFormField() {
    return (
      <>
        <FormControl fullWidth>
          <TextField
            size="small"
            error={newDoctor.firstName.valid === false}
            label="Nome"
            helperText={
              newDoctor.firstName.valid === false
                ? "Il campo Nome è obbligatorio e deve contenere solo lettere."
                : ""
            }
            id="doctorName"
            onBlur={(e) => {
              setNewDoctor({
                ...newDoctor,
                firstName: {
                  value: e.target.value,
                },
              });
            }}
          />
        </FormControl>
      </>
    );
  }

  function LastNameFormField() {
    return (
      <>
        <FormControl required fullWidth>
          <TextField
            size="small"
            error={newDoctor.lastName.valid === false}
            helperText={
              newDoctor.lastName.valid === false
                ? "Il campo Cognome è obbligatorio e deve contenere solo lettere."
                : ""
            }
            label="Cognome"
            id="doctoreLastName"
            onBlur={(e) => {
              setNewDoctor({
                ...newDoctor,
                lastName: {
                  value: e.target.value,
                },
              });
            }}
          />
        </FormControl>
      </>
    );
  }

  function SsnFormField() {
    return (
      <>
        <FormControl fullWidth>
          <TextField
            size="small"
            error={newDoctor.ssn.valid === false}
            helperText={
              newDoctor.ssn.valid === false
                ? "Il campo Codice Fiscale è obbligatorio. Inseriscine uno valido."
                : ""
            }
            onBlur={(e) => {
              setNewDoctor({
                ...newDoctor,
                ssn: {
                  value: e.target.value,
                },
              });
            }}
            fullWidth
            id="ssn"
            label="Codice fiscale"
          ></TextField>
        </FormControl>
      </>
    );
  }

  function FormRow(formColumns) {
    return (
      <>
        {formColumns.map((column) => {
          return (
            <Grid item key={column} xs={12 / formColumns.length}>
              {column}
            </Grid>
          );
        })}
      </>
    );
  }

  function EmailFormField() {
    return (
      <>
        <FormControl fullWidth>
          <TextField
            size="small"
            error={newDoctor.email.valid === false}
            helperText={
              newDoctor.email.valid === false
                ? "Il campo Email è obbligatorio. Inseriscine una valida."
                : ""
            }
            onBlur={(e) => {
              setNewDoctor({
                ...newDoctor,
                email: { value: e.target.value },
              });
            }}
            id="useremail"
            label="Email"
            type="email"
          ></TextField>
        </FormControl>
      </>
    );
  }

  function UsernameFormField() {
    return (
      <>
        <FormControl fullWidth>
          <TextField
            size="small"
            error={newDoctor.username.valid === false}
            helperText={
              newDoctor.username.valid === false
                ? "Il campo Username è obbligatorio. Lunghezza minima 8 caratteri."
                : ""
            }
            onBlur={(e) => {
              setNewDoctor({
                ...newDoctor,
                username: { value: e.target.value },
              });
            }}
            id="username"
            label="Username"
          ></TextField>
        </FormControl>
      </>
    );
  }

  function PasswordFormField() {
    return (
      <>
        <FormControl fullWidth>
          <TextField
            size="small"
            error={newDoctor.password.valid === false}
            helperText={
              newDoctor.password.valid === false
                ? "Il campo Password è obbligatorio. Lunghezza minima 8 caratteri."
                : ""
            }
            onBlur={(e) => {
              setNewDoctor({
                ...newDoctor,
                password: { value: e.target.value },
              });
            }}
            id="password"
            type={showPassword ? "text" : "password"}
            label="Password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleTogglePassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          ></TextField>
        </FormControl>
      </>
    );
  }

  return (
    <Box
      component="form"
      sx={{ m: 1, marginLeft: "32px", marginRight: "32px" }}
      noValidate
      autoComplete="off"
      paddingTop="8px"
    >
      <Box sx={{ flexGrow: 1 }}>
        <FormGroup>
          <Grid container spacing={1}>
            <Grid container item spacing={2} style={{ marginBottom: "24px" }}>
              {FormRow([FirstNameFormField(), LastNameFormField()])}
            </Grid>
            <Grid container item spacing={2} style={{ marginBottom: "24px" }}>
              {FormRow([SsnFormField()])}
            </Grid>
            <Grid container item spacing={2} style={{ marginBottom: "24px" }}>
              {FormRow([EmailFormField()])}
            </Grid>
            <Grid container item spacing={2} style={{ marginBottom: "24px" }}>
              {FormRow([UsernameFormField(), PasswordFormField()])}
            </Grid>
          </Grid>
        </FormGroup>
      </Box>
      <LoadingButton
        loading={submittingForm}
        onClick={(e) => handleNewDoctorSubmit(e)}
        style={{ marginTop: "18px" }}
        variant="contained"
      >
        Crea dottore
      </LoadingButton>
    </Box>
  );
}

export { CreateDoctor };
