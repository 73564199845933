import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { Button, IconButton, ButtonGroup, TextField } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useEffect } from "react";
import {
  getPatientSteps,
  getPatientSleep,
  getPatientStepsThreshold,
  getHearthThreshold,
  getPatientHRS,
  getSleepThreshold,
} from "../../actions/patient.js";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { BarChart } from "./BarChart";
import { Soglia } from "./Soglia.js";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/it";
export const colors = {
  low: "#66CC66", // verde
  mid: "#FFA500", // arancione
  high: "#FF6666", // rosso
};

export const colors2 = {
  profondo: "#66CC66", // verde
  leggero: "#FFCC00", // giallo
  REM: "#FFA500", // arancione
  sveglio: "#FF6666", // rosso
};

const selector = (state) => state.auth;

function Data(props) {
  const {
    patientSteps,
    patientSleep,
    hrsAvarage,
    stepsAvarage,
    sleepAvarage,
    hrThreshold,
    patientHRS,
  } = useSelector(selector);
  const [value3, setValue3] = useState("steps");
  const [stepsLoading, setStepsLoading] = useState(true);
  const [sleepLoading, setSleepLoading] = useState(true);
  const [hearthLoading, setHearthLoading] = useState(true);
  const [dateView, setDateView] = useState("day");
  const [stepsThresholdLoading, setStepsThresholdLoading] = useState(true);
  const dispatch = useDispatch();
  let { patientId } = useParams();
  patientId = patientId.replace("P", "");
  const [dateOne, setDateOne] = useState(dayjs().subtract(1, "day"));
  const [dateTwo, setDateTwo] = useState(dayjs().subtract(1, "day"));

  const getColor = (bar) => {
    return colors[bar.id];
  };

  const getColor2 = (bar) => {
    return colors2[bar.id];
  };

  useEffect(() => {
    if (hearthLoading) {
      dispatch(
        getPatientHRS(
          patientId,
          dayjs(dateOne).format("YYYY-MM-DD"),
          dayjs(dateTwo).format("YYYY-MM-DD"),
        ),
      );
      dispatch(getHearthThreshold(patientId));
      setTimeout(() => {
        setHearthLoading(false);
      }, 3000);
    }
    if (stepsLoading) {
      setStepsLoading(false);
      dispatch(
        getPatientSteps(
          patientId,
          dayjs(dateOne).format("YYYY-MM-DD"),
          dayjs(dateTwo).format("YYYY-MM-DD"),
        ),
      );
    }
    if (stepsThresholdLoading) {
      setStepsThresholdLoading(false);
      dispatch(getPatientStepsThreshold(patientId));
    }
    if (sleepLoading) {
      setSleepLoading(false);
      dispatch(
        getPatientSleep(
          patientId,
          dayjs(dateOne).format("YYYY-MM-DD"),
          dayjs(dateTwo).format("YYYY-MM-DD"),
        ),
      );
      dispatch(getSleepThreshold(patientId));
    }
  }, [
    hearthLoading,
    stepsLoading,
    stepsThresholdLoading,
    sleepLoading,
    dispatch,
    dateOne,
    dateTwo,
    patientId,
  ]);

  return (
    <>
      <TabContext value={value3}>
        <div style={{ paddingLeft: "32px" }}>
          <ButtonGroup size="small" aria-label="small button group">
            <Button
              key="one"
              variant={dateView === "day" ? "contained" : "outlined"}
              onClick={() => {
                setStepsLoading(true);
                setSleepLoading(true);
                setHearthLoading(true);
                setDateView("day");
                setDateOne(dateTwo);
              }}
            >
              GIORNO
            </Button>
            <Button
              key="two"
              disabled={value3 === "blood"}
              variant={dateView === "week" ? "contained" : "outlined"}
              onClick={() => {
                setStepsLoading(true);
                setSleepLoading(true);

                setHearthLoading(true);
                setDateView("week");
                setDateTwo(dateOne);
                setDateOne(dayjs(dateOne).add(-6, "day"));
              }}
            >
              SETTIMANA
            </Button>
          </ButtonGroup>
          <IconButton
            onClick={() => {
              if (dateView === "day") {
                setDateOne(dayjs(dateOne).add(-1, "day"));
                setDateTwo(dayjs(dateOne).add(-1, "day"));
              } else {
                setDateTwo(dayjs(dateTwo).add(-6, "day"));
                setDateOne(dayjs(dateOne).add(-6, "day"));
              }
              setStepsLoading(true);
              setSleepLoading(true);

              setHearthLoading(true);
            }}
          >
            <ArrowBack></ArrowBack>
          </IconButton>
          <TextField
            sx={{
              display: "content",
              marginRight: "8px",
              textAlign: "center",
            }}
            value={dayjs(dateOne).format("DD-MM-YYYY")}
            disabled
          ></TextField>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
            <DatePicker
              maxDate={dayjs().subtract(1, "day")}
              format="DD-MM-YYYY"
              sx={{ verticalAlign: "middle" }}
              onChange={(value) => {
                setStepsLoading(true);
                setSleepLoading(true);
                setHearthLoading(true);
                if (dateView === "day") {
                  setDateTwo(value);
                  setDateOne(value);
                } else {
                  setDateTwo(value);
                  setDateOne(dayjs(value).add(-6, "day"));
                }
              }}
              value={dateTwo}
            />
          </LocalizationProvider>
          <IconButton
            onClick={() => {
              setStepsLoading(true);
              setSleepLoading(true);
              setHearthLoading(true);
              if (dateView === "day") {
                const newDateOne = dayjs(dateOne).add(1, "day");
                if (newDateOne.isAfter(dayjs().subtract(1, "day"))) {
                  setDateOne(dayjs().subtract(1, "day"));
                  setDateTwo(dayjs().subtract(1, "day"));
                } else {
                  setDateOne(newDateOne);
                  setDateTwo(newDateOne);
                }
              } else {
                const newDateTwo = dayjs(dateTwo).add(6, "day");
                if (newDateTwo.isAfter(dayjs().subtract(1, "day"))) {
                  setDateOne(dayjs().subtract(7, "day"));
                  setDateTwo(dayjs().subtract(1, "day"));
                } else {
                  setDateTwo(newDateTwo);
                  setDateOne(dayjs(dateOne).add(6, "day"));
                }
              }
            }}
          >
            <ArrowForward></ArrowForward>
          </IconButton>
        </div>
        <TabList
          style={{ paddingLeft: "32px" }}
          onChange={(event, newValue) => {
            setValue3(newValue);
          }}
          aria-label="lab API tabs example"
        >
          <Tab label="Passi" value="steps" />
          <Tab label="RHR" value="rhr" />
          <Tab label="HR ZONE" value="hr-zone" />
          <Tab label="Sonno" value="sleep" />
        </TabList>
        <div
          style={{
            height: 500,
            paddingBottom: 50,
            width: "calc(100vw - 302px)",
            paddingLeft: "8px",
          }}
        >
          <TabPanel value="steps">
            <Soglia name="Passi"></Soglia>
            <BarChart
              data={patientSteps.map((measure) => {
                return {
                  Passi: measure.steps,
                  Data: measure.date,
                };
              })}
              keys={["Passi"]}
              indexBy="Data"
              maxValue="auto"
              avg={stepsAvarage}
              legendX="Data"
              legendY="Passi"
              colors={{ scheme: "paired" }}
            ></BarChart>
            {/* <DataGrid sx={{height: 500}} columns={columnsSteps} rows={patientSteps.map((row,index) => {
              return {
                ...row,
              id : index
              }
            })} pageSize={10} rowsPerPageOptions loading={stepsLoading}></DataGrid> */}
          </TabPanel>
          <TabPanel value="rhr">
            <Soglia name="Battiti"></Soglia>
            <BarChart
              data={patientHRS.map((item) => {
                return { ...item, "Media BPM a riposo": item.rest_rate };
              })}
              keys={["Media BPM a riposo"]}
              avg={hrsAvarage}
              minValue={0}
              thresholds={{
                min: hrThreshold?.restMin,
                max: hrThreshold?.restMax,
              }}
              maxValue="auto"
              indexBy="date"
              legendX="Data"
              groupMode="grouped"
              legendY="Media giornaliera BPM a riposo"
              colors={{ scheme: "paired" }}
            ></BarChart>
          </TabPanel>
          <TabPanel value="hr-zone">
            <BarChart
              data={patientHRS.map((item) => {
                return {
                  ...item,
                  date: item.date,
                  high: item.high_time,
                  mid: item.mid_time,
                  low: item.low_time,
                };
              })}
              keys={["low", "mid", "high"]}
              indexBy="date"
              legendX="Data"
              groupMode="stacked"
              legendY="Tempo fascia cardiaca (ore e minuti)"
              colors={getColor}
              time={true}
            ></BarChart>
          </TabPanel>
          <TabPanel value="sleep">
            <Soglia name="Sonno"></Soglia>
            <BarChart
              data={patientSleep.map((sleep) => {
                return {
                  date: sleep.date,
                  profondo: sleep.minDeep,
                  leggero: sleep.minLight,
                  REM: sleep.minRem,
                  sveglio: sleep.minWake,
                };
              })}
              keys={["profondo", "leggero", "REM", "sveglio"]}
              indexBy="date"
              avg={sleepAvarage}
              legendX="Data"
              maxValue="auto"
              legendY="Durata (ore e minuti)"
              colors={getColor2}
              time={true}
            ></BarChart>
          </TabPanel>
        </div>
      </TabContext>
    </>
  );
}

export { Data };
