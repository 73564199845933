import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getPatientQuestionnaire } from "../../actions/patient";
import { useDispatch, useSelector } from "react-redux";
import { Fragment } from "react";
import { getPatientWeights } from "../../actions/patient";
import dayjs from "dayjs";

const selector = (state) => state.auth;

function QuestionnairePatient(props) {
  const dispatch = useDispatch();
  const { questionnaire, isLoggedIn, patientWeight } = useSelector(selector);
  const [questionnaireLoading, setQuestionnaireLoading] = useState(true);
  const navigate = useNavigate();
  const [punteggiSezioni, setPunteggiSezioni] = useState({});
  const [punteggiMPI, setPunteggiMPI] = useState({});
  let { patientId, questionnaireId } = useParams();
  patientId = patientId.replace("P", "");
  const [patientWeightLoading, setPatientWeightLoading] = useState(true);

  useEffect(() => {
    if (isLoggedIn) {
      if (questionnaireLoading) {
        dispatch(getPatientQuestionnaire(patientId, questionnaireId));
        setQuestionnaireLoading(false);
      }
    } else {
      navigate("/form-login");
    }
  }, [
    isLoggedIn,
    questionnaireLoading,
    dispatch,
    patientId,
    questionnaireId,
    navigate,
  ]);

  useEffect(() => {
    if (
      patientWeightLoading &&
      questionnaire?.questionnaireTemplate?.name ===
        "INDICE PROGNOSTICO MULTIDIMENSIONALE (MPI)"
    ) {
      setPatientWeightLoading(false);
      dispatch(getPatientWeights(patientId));
    }
  }, [patientWeightLoading, dispatch, patientId, questionnaire]);

  useEffect(() => {
    if (
      questionnaire?.questionnaireTemplate?.name ===
      "INDICE PROGNOSTICO MULTIDIMENSIONALE (MPI)"
    ) {
      const punteggiSezioni = calcolaPunteggiSezioni(questionnaire);
      setPunteggiSezioni(punteggiSezioni);
      const punteggiMPI = calcolaPunteggioMPI(punteggiSezioni);
      setPunteggiMPI(punteggiMPI);
    }
  }, [questionnaire]);

  // Raggruppare le domande per sezione
  const questionsBySection = {};
  questionnaire?.questionAnswers?.forEach((question) => {
    const sectionId = question.question.questionSection.id;
    if (!questionsBySection[sectionId]) {
      questionsBySection[sectionId] = {
        sectionName: question.question.questionSection.name,
        questions: [],
      };
    }
    questionsBySection[sectionId].questions.push(question);
  });

  const calcolaPunteggiSezioni = (questionnaire) => {
    const punteggiSezioni = {};

    // Itera attraverso le risposte del questionario
    questionnaire.questionAnswers.forEach((risposta) => {
      const { questionSection } = risposta.question;

      // Inizializza la sezione se non esiste ancora
      if (!punteggiSezioni[questionSection.name]) {
        punteggiSezioni[questionSection.name] = {
          name: questionSection.name,
          score: 0,
        };
      }

      // Aggiungi il punteggio della risposta alla sezione corrispondente
      if (
        questionSection.name ===
        "CIRS-CI - Cumulative Illness Rating Scale - Comorbidity Index"
      ) {
        if (risposta.chosenAnswer?.score >= 3) {
          punteggiSezioni[questionSection.name].score += 1;
        }
      } else {
        punteggiSezioni[questionSection.name].score +=
          risposta.chosenAnswer?.score || 0;
      }
    });

    return punteggiSezioni;
  };

  const calcolaPunteggioMPI = (punteggiSezioni) => {
    const punteggiMPI = {};

    for (let sezione in punteggiSezioni) {
      let punteggioMPI = 0;

      if (
        punteggiSezioni[sezione].name === "ADL - Activities of Daily Living"
      ) {
        if (punteggiSezioni[sezione].score <= 2) {
          punteggioMPI = 1;
        } else if (
          punteggiSezioni[sezione].score >= 3 &&
          punteggiSezioni[sezione].score <= 4
        ) {
          punteggioMPI = 0.5;
        }
      } else if (
        punteggiSezioni[sezione].name ===
        "IADL - Instrumental Activities of Daily Living"
      ) {
        if (punteggiSezioni[sezione].score <= 3) {
          punteggioMPI = 1;
        } else if (
          punteggiSezioni[sezione].score >= 4 &&
          punteggiSezioni[sezione].score <= 5
        ) {
          punteggioMPI = 0.5;
        }
      } else if (
        punteggiSezioni[sezione].name === "Barthel MOB - Barthel Mobility"
      ) {
        if (punteggiSezioni[sezione].score === 0) {
          punteggioMPI = 1;
        } else if (punteggiSezioni[sezione].score === 1) {
          punteggioMPI = 0.5;
        }
      } else if (
        punteggiSezioni[sezione].name ===
        "SPMSQ - Short Portable Mental Status Questionnaire"
      ) {
        if (
          punteggiSezioni[sezione].score >= 8 &&
          punteggiSezioni[sezione].score <= 10
        ) {
          punteggioMPI = 1;
        } else if (
          punteggiSezioni[sezione].score >= 4 &&
          punteggiSezioni[sezione].score <= 7
        ) {
          punteggioMPI = 0.5;
        }
      } else if (
        punteggiSezioni[sezione].name ===
        "MNA-SF - Mini-Nutritional Assessment short form"
      ) {
        if (punteggiSezioni[sezione].score <= 7) {
          punteggioMPI = 1;
        } else if (
          punteggiSezioni[sezione].score >= 8 &&
          punteggiSezioni[sezione].score <= 11
        ) {
          punteggioMPI = 0.5;
        }
      } else if (
        punteggiSezioni[sezione].name ===
        "CIRS-CI - Cumulative Illness Rating Scale - Comorbidity Index"
      ) {
        if (punteggiSezioni[sezione].score >= 3) {
          punteggioMPI = 1;
        } else if (
          punteggiSezioni[sezione].score <= 2 &&
          punteggiSezioni[sezione].score >= 1
        ) {
          punteggioMPI = 0.5;
        }
      } else if (punteggiSezioni[sezione].name === "Numero di farmaci") {
        if (punteggiSezioni[sezione].score === 0) {
          punteggioMPI = 0;
        } else if (punteggiSezioni[sezione].score === 1) {
          punteggioMPI = 0.5;
        } else {
          punteggioMPI = 1;
        }
      } else if (punteggiSezioni[sezione].name === "Stato di co-abitazione") {
        if (punteggiSezioni[sezione].score === 0) {
          punteggioMPI = 0;
        } else if (punteggiSezioni[sezione].score === 1) {
          punteggioMPI = 0.5;
        } else {
          punteggioMPI = 1;
        }
      }

      punteggiMPI[sezione] = punteggioMPI;
    }

    return punteggiMPI;
  };

  const determinaCategoriaMPI = (rischio) => {
    if (rischio >= 0.0 && rischio <= 0.33) {
      return "Il soggetto ha un basso rischio di sviluppare outcome clinici negativi. Non è in una condizione di fragilità.";
    } else if (rischio > 0.33 && rischio <= 0.66) {
      return "Il soggetto ha un rischio moderato di sviluppare outcome clinici negativi. È in una condizione di pre-fragilità.";
    } else if (rischio > 0.66 && rischio <= 1.0) {
      return "Il soggetto ha un rischio elevato di sviluppare outcome clinici negativi. È in una condizione di fragilità.";
    }
  };

  const determinaRischioMPI = (rischio) => {
    if (rischio === 0) {
      return "Rischio basso";
    } else if (rischio === 0.5) {
      return "Rischio medio";
    } else if (rischio > 0.66 && rischio <= 1.0) {
      return "Rischio alto";
    }
  };

  const determinaCategoriaInsonnia = (valore) => {
    if (valore >= 0 && valore <= 7) {
      return "Assenza d'insonnia clinicamente significativa.";
    } else if (valore >= 8 && valore <= 14) {
      return "Insonnia al di sotto della soglia clinica.";
    } else if (valore >= 15 && valore <= 21) {
      return "Insonnia clinica (media gravità).";
    } else if (valore >= 22 && valore <= 28) {
      return "Insonnia clinica (grave).";
    }
  };

  return (
    <div style={{ paddingTop: 16 }}>
      <Paper
        sx={{
          width: "calc(100vw - 302px)",
          marginLeft: "32px",
        }}
      >
        <div></div>
        <TableContainer sx={{ maxHeight: 550 }}>
          <Table stickyHeader aria-label="sticky table">
            <caption>
              Nome: {questionnaire?.questionnaireTemplate?.name}
              <br />
              <br />
              {questionnaire?.questionnaireTemplate?.name ===
              "INDICE PROGNOSTICO MULTIDIMENSIONALE (MPI)" ? (
                <>
                  MPI: {questionnaire.score / 100}/1 -{" "}
                  {determinaCategoriaMPI(questionnaire.score / 100)}
                </>
              ) : questionnaire?.questionnaireTemplate?.name ===
                "INSOMNIA SEVERITY INDEX" ? (
                <>
                  Punteggio: {questionnaire.score}/
                  {questionnaire?.questionnaireTemplate?.maxScore} -{" "}
                  {determinaCategoriaInsonnia(questionnaire.score)}
                </>
              ) : (
                `Punteggio: ${questionnaire.score}/${questionnaire?.questionnaireTemplate?.maxScore}`
              )}
            </caption>
            <TableHead>
              <TableRow>
                <TableCell>Domanda</TableCell>
                <TableCell>Descrizione</TableCell>
                <TableCell>Risposte</TableCell>
                <TableCell>Punti</TableCell>
                <TableCell>Risposta data</TableCell>
                <TableCell>Punti risposta</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.values(questionsBySection).map((section) => {
                const punteggioSezione =
                  punteggiSezioni[section.sectionName]?.score;
                const rischioSezione = punteggiMPI[section.sectionName];
                return (
                  <Fragment key={section.sectionName}>
                    <TableRow>
                      <TableCell colSpan={6} style={{ fontWeight: "bold" }}>
                        {section.sectionName}
                        {questionnaire?.questionnaireTemplate?.name ===
                        "INDICE PROGNOSTICO MULTIDIMENSIONALE (MPI)" ? (
                          <>
                            <br />
                            Punteggio: {punteggioSezione}
                            <br />
                            Rischio: {rischioSezione} -{" "}
                            {determinaRischioMPI(rischioSezione)}
                          </>
                        ) : null}
                      </TableCell>
                    </TableRow>
                    {section.questions.map((question) =>
                      question.question.text !== "Indice di massa corporea" ? (
                        <TableRow key={question.id}>
                          <TableCell>
                            {question.question.text.replace("undefined", "")}
                          </TableCell>
                          <TableCell>
                            {question.question.description.replace(
                              "undefined",
                              "",
                            )}
                          </TableCell>
                          <TableCell>
                            {question.question.possibleQuestionAnswer.map(
                              (answer) => (
                                <p key={answer.id}>{answer.text}</p>
                              ),
                            )}
                          </TableCell>
                          <TableCell>
                            {question.question.possibleQuestionAnswer
                              .sort((a, b) => a.id - b.id)
                              .map((answer) => (
                                <p key={answer.id}>{answer.score || 0}</p>
                              ))}
                          </TableCell>
                          <TableCell>{question.chosenAnswer.text}</TableCell>
                          <TableCell>
                            {question.chosenAnswer.score || 0}
                          </TableCell>
                        </TableRow>
                      ) : (
                        <TableRow key={question.id}>
                          <TableCell colSpan={5}>
                            Il BMI del paziente è{" "}
                            {
                              patientWeight.find(
                                (w) =>
                                  w.date ===
                                  dayjs(questionnaire.submissionDate).format(
                                    "YYYY-MM-DD",
                                  ),
                              )?.bmi
                            }
                          </TableCell>

                          <TableCell>
                            {question.chosenAnswer.score || 0}
                          </TableCell>
                        </TableRow>
                      ),
                    )}
                  </Fragment>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

export { QuestionnairePatient };
