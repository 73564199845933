import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getQuestionnairesTemplates } from "../../actions/questionnaire";
import { useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { compileQuestionnaire, getPatientWeights } from "../../actions/patient";
import { useParams, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Button } from "@mui/material";
import { insertPatientWeight } from "../../actions/patient";
import { useCallback } from "react";

const selector = (state) => state.auth;

function CompileQuestionnaire(props) {
  const { questionnaires } = useSelector(selector);
  const [questionnairesLoading, setIsQuestionnairesLoading] = useState(true);
  const [questionnaire, setQuestionnaire] = useState(null);
  const [isQuestionnaireLoading, setIsQuestionnaireLoading] = useState(false);
  let { patientId } = useParams();
  patientId = patientId.replace("P", "");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { patientWeight } = useSelector(selector);
  const [patientWeightLoading, setPatientWeightLoading] = useState(true);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [newPatientWeight, setNewPatientWeight] = useState({
    weight: 0,
    date: dayjs().format("YYYY-MM-DD"),
  });
  const [selected, setSelected] = useState(false);

  const handleCheckboxChange = useCallback(
    (question, answer) => {
      // Logica per la gestione del cambiamento della checkbox
      let isCompiled = true;
      setQuestionnaire({
        ...questionnaire,
        questions: questionnaire.questions.map((q) => {
          if (q.id === question.id) {
            q.possibleQuestionAnswer = q.possibleQuestionAnswer.map((a) => {
              if (a.id === answer.id) {
                a.selected = true;
              } else {
                a.selected = false;
              }
              return a;
            });
          }
          return q;
        }),
      });

      for (let q of questionnaire.questions) {
        if (
          q.possibleQuestionAnswer.filter((a) => a.selected === true).length ===
          0
        ) {
          isCompiled = false;
          break;
        }
      }

      setQuestionnaire({
        ...questionnaire,
        isCompiled: isCompiled,
      });
    },
    [questionnaire],
  );

  useEffect(() => {
    if (patientWeight != null && selected === false) {
      const bmi = patientWeight.find(
        (w) => w.date === dayjs().format("YYYY-MM-DD"),
      )?.bmi;

      const intervallo = calcolaIntervalloIMC(bmi);

      const question = questionnaire?.questions?.find(
        (q) => q.text === "Indice di massa corporea",
      );

      const answer = question?.possibleQuestionAnswer?.find(
        (a) => a.text === intervallo,
      );
      if (question && answer) {
        handleCheckboxChange(question, answer);
        setSelected(true);
      }
    }
  }, [patientWeight, questionnaire, handleCheckboxChange, selected]);

  useEffect(() => {
    if (patientWeightLoading) {
      setPatientWeightLoading(false);
      dispatch(getPatientWeights(patientId));
    }
  }, [patientWeightLoading, dispatch, patientId]);

  useEffect(() => {
    if (questionnairesLoading) {
      setIsQuestionnairesLoading(false);
      dispatch(getQuestionnairesTemplates());
    }
  }, [questionnairesLoading, dispatch]);

  const calcolaPunteggiSezioni = (questionnaire) => {
    const punteggiSezioni = {};

    // Itera attraverso le domande del questionario
    questionnaire.questions.forEach((domanda) => {
      const { questionSection } = domanda;

      // Controlla se la sezione è già stata inizializzata nel risultato
      if (!punteggiSezioni[questionSection.id]) {
        punteggiSezioni[questionSection.id] = {
          name: questionSection.name,
          score: 0,
        };
      }

      if (
        questionSection.name ===
        "CIRS-CI - Cumulative Illness Rating Scale - Comorbidity Index"
      ) {
        const selectedAnswer = domanda.possibleQuestionAnswer.find(
          (answer) => answer.selected,
        );
        if (selectedAnswer?.score >= 3) {
          punteggiSezioni[questionSection.id].score += 1;
        }
      } else {
        // Calcola il punteggio della domanda corrente e aggiungilo al punteggio della sezione
        const selectedAnswer = domanda.possibleQuestionAnswer.find(
          (answer) => answer.selected,
        );
        punteggiSezioni[questionSection.id].score += selectedAnswer?.score || 0;
      }
    });
    return punteggiSezioni;
  };

  const renderQuestionsBySection = (questions) => {
    const sections = {};

    // Raggruppa le domande per sezione
    questions.forEach((question) => {
      const sectionId = question.questionSection.id;

      if (!sections[sectionId]) {
        sections[sectionId] = {
          name: question.questionSection.name,
          questions: [],
        };
      }

      sections[sectionId].questions.push(question);
    });

    // Restituisci il rendering delle domande raggruppate per sezione
    return Object.values(sections).map((section) => (
      <div
        key={section.name}
        style={{ margin: "20px 0", border: "1px solid #ccc", padding: "10px" }}
      >
        <Typography
          variant="h5"
          style={{ fontWeight: "bold", paddingBottom: "15px" }}
        >
          {section.name}
        </Typography>
        {section.questions.map((question, index) =>
          question.text !== "Indice di massa corporea" ? (
            <div
              key={question.id}
              style={{ paddingTop: index > 0 ? "10px" : "0" }}
            >
              <strong>{question.text.replace("undefined", "")}</strong>{" "}
              <>{question.description.replace(undefined, "")}</>
              <FormGroup style={{ backgroundColor: "white" }}>
                {question.possibleQuestionAnswer
                  .sort((a, b) => a.id - b.id)
                  .map((answer) => (
                    <FormControlLabel
                      key={answer.id}
                      control={
                        <Checkbox
                          disabled={isQuestionnaireLoading}
                          defaultChecked
                          checked={!!answer.selected}
                          onChange={() => {
                            handleCheckboxChange(question, answer);
                          }}
                        />
                      }
                      label={answer.text}
                    />
                  ))}
              </FormGroup>
              <Divider />
            </div>
          ) : (
            <div
              key={question.id}
              style={{ paddingTop: index > 0 ? "10px" : "0" }}
            >
              <strong>{question.text.replace("undefined", "")}</strong>{" "}
              <>{question.description.replace(undefined, "")}</>
              <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                {" "}
                Il BMI del paziente è{" "}
                {
                  patientWeight.find(
                    (w) => w.date === dayjs().format("YYYY-MM-DD"),
                  ).bmi
                }
              </div>
              <Divider />
            </div>
          ),
        )}
      </div>
    ));
  };

  const calcolaIntervalloIMC = (imc) => {
    if (imc < 19) {
      return "IMC < 19";
    } else if (imc >= 19 && imc < 21) {
      return "19 ≤ IMC < 21";
    } else if (imc >= 21 && imc < 23) {
      return "21 ≤ IMC < 23";
    } else {
      // IMC ≥ 23
      return "IMC ≥ 23";
    }
  };

  const calcolaPunteggioMPI = (punteggiSezioni) => {
    let punteggioMPI = 0;

    for (let sezione in punteggiSezioni) {
      if (
        punteggiSezioni[sezione].name === "ADL - Activities of Daily Living"
      ) {
        if (punteggiSezioni[sezione].score <= 2) {
          punteggioMPI += 1;
        } else if (
          punteggiSezioni[sezione].score >= 3 &&
          punteggiSezioni[sezione].score <= 4
        ) {
          punteggioMPI += 0.5;
        } else {
          punteggioMPI += 0;
        }
      } else if (
        punteggiSezioni[sezione].name ===
        "IADL - Instrumental Activities of Daily Living"
      ) {
        if (punteggiSezioni[sezione].score <= 3) {
          punteggioMPI += 1;
        } else if (
          punteggiSezioni[sezione].score >= 4 &&
          punteggiSezioni[sezione].score <= 5
        ) {
          punteggioMPI += 0.5;
        } else {
          punteggioMPI += 0;
        }
      } else if (
        punteggiSezioni[sezione].name === "Barthel MOB - Barthel Mobility"
      ) {
        if (punteggiSezioni[sezione].score === 0) {
          punteggioMPI += 1;
        } else if (punteggiSezioni[sezione].score === 1) {
          punteggioMPI += 0.5;
        } else {
          punteggioMPI += 0;
        }
      } else if (
        punteggiSezioni[sezione].name ===
        "SPMSQ - Short Portable Mental Status Questionnaire"
      ) {
        if (
          punteggiSezioni[sezione].score >= 8 &&
          punteggiSezioni[sezione].score <= 10
        ) {
          punteggioMPI += 1;
        } else if (
          punteggiSezioni[sezione].score >= 4 &&
          punteggiSezioni[sezione].score <= 7
        ) {
          punteggioMPI += 0.5;
        } else {
          punteggioMPI += 0;
        }
      } else if (
        punteggiSezioni[sezione].name ===
        "MNA-SF - Mini-Nutritional Assessment short form"
      ) {
        if (punteggiSezioni[sezione].score <= 7) {
          punteggioMPI += 1;
        } else if (
          punteggiSezioni[sezione].score >= 8 &&
          punteggiSezioni[sezione].score <= 11
        ) {
          punteggioMPI += 0.5;
        } else {
          punteggioMPI += 0;
        }
      } else if (
        punteggiSezioni[sezione].name ===
        "CIRS-CI - Cumulative Illness Rating Scale - Comorbidity Index"
      ) {
        if (punteggiSezioni[sezione].score >= 3) {
          punteggioMPI += 1;
        } else if (
          punteggiSezioni[sezione].score <= 2 &&
          punteggiSezioni[sezione].score >= 1
        ) {
          punteggioMPI += 0.5;
        } else {
          punteggioMPI += 0;
        }
      } else if (punteggiSezioni[sezione].name === "Numero di farmaci") {
        if (punteggiSezioni[sezione].score === 0) {
          punteggioMPI += 0;
        } else if (punteggiSezioni[sezione].score === 1) {
          punteggioMPI += 0.5;
        } else {
          punteggioMPI += 1;
        }
      } else if (punteggiSezioni[sezione].name === "Stato di co-abitazione") {
        if (punteggiSezioni[sezione].score === 0) {
          punteggioMPI += 0;
        } else if (punteggiSezioni[sezione].score === 1) {
          punteggioMPI += 0.5;
        } else {
          punteggioMPI += 1;
        }
      }
    }
    return punteggioMPI;
  };

  return (
    <div
      style={{
        width: "calc(100vw - 302px)",
        backgroundColor: "white",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          zIndex: 2,
          position: "fixed",
          width: "100%",
          paddingBottom: 10,
          paddingTop: 16,
          paddingLeft: 32,
        }}
      >
        <Grid container spacing={5}>
          <Grid item>
            <TextField
              size="small"
              required
              sx={{ minWidth: "220px" }}
              label="Seleziona questionario"
              select
              onChange={(event) => {
                let _questionnaire = event.target.value;
                _questionnaire.questions.map((q) => {
                  q.possibleQuestionAnswer = q.possibleQuestionAnswer.map(
                    (a) => {
                      a.selected = false;
                      return a;
                    },
                  );
                  return q;
                });
                setQuestionnaire(_questionnaire);
                if (
                  _questionnaire.name ===
                    "INDICE PROGNOSTICO MULTIDIMENSIONALE (MPI)" &&
                  patientWeight != null &&
                  patientWeight.find(
                    (w) => w.date === dayjs().format("YYYY-MM-DD"),
                  ) === undefined
                ) {
                  setIsDialogOpen(true);
                }
              }}
            >
              {questionnaires.map((questionnaire, index) => (
                <MenuItem key={index} value={questionnaire}>
                  {questionnaire.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item>
            <LoadingButton
              loading={isQuestionnaireLoading}
              disabled={!questionnaire?.isCompiled}
              variant="contained"
              color="primary"
              onClick={() => {
                setIsQuestionnaireLoading(true);
                setTimeout(() => {
                  let score = 0;

                  if (
                    questionnaire.name !==
                    "INDICE PROGNOSTICO MULTIDIMENSIONALE (MPI)"
                  ) {
                    for (const question of questionnaire.questions) {
                      const selectedAnswer =
                        question.possibleQuestionAnswer.find(
                          (answer) => answer.selected,
                        );

                      if (selectedAnswer) {
                        score += selectedAnswer.score; // Assumendo che ogni possibile risposta abbia un campo "score"
                      }
                    }
                  } else {
                    const punteggiSezioni =
                      calcolaPunteggiSezioni(questionnaire);
                    score =
                      (Math.round(
                        (calcolaPunteggioMPI(punteggiSezioni) / 8 +
                          Number.EPSILON) *
                          100,
                      ) /
                        100) *
                      100;
                  }
                  const dtoQuestionnaire = {
                    submissionDate: new Date().toISOString(),
                    questionAnswers: questionnaire.questions.map(
                      (question) => ({
                        questionId: question.id,
                        possibleQuestionAnswerId:
                          question.possibleQuestionAnswer
                            .filter((a) => a.selected === true)
                            .pop().id,
                      }),
                    ),
                    questionnaireTemplateId: questionnaire?.id,
                    score: score,
                  };

                  dispatch(
                    compileQuestionnaire(patientId, dtoQuestionnaire),
                  ).then(
                    (res) => {
                      setIsQuestionnaireLoading(false);
                      navigate("/doctor/patients/" + patientId);
                      enqueueSnackbar("Questionario compilato con successo", {
                        variant: "success",
                      });
                    },
                    (err) => {
                      setIsQuestionnaireLoading(false);
                      dispatch({
                        type: "ERROR",
                        payload:
                          "Errore durante la compilazione del questionario",
                      });
                    },
                  );
                }, 1500);
              }}
            >
              Conferma
            </LoadingButton>
          </Grid>
        </Grid>
      </div>

      <div style={{ paddingTop: 60, paddingLeft: 32 }}>
        {questionnaire != null ? (
          questionnaire.name === "INDICE PROGNOSTICO MULTIDIMENSIONALE (MPI)" &&
          patientWeight != null &&
          patientWeight.find((w) => w.date === dayjs().format("YYYY-MM-DD")) ===
            undefined ? (
            <Dialog open={isDialogOpen}>
              <DialogTitle>
                Prima di compilare questo questionario è necessario aggiungere
                il peso di oggi (Kg)
              </DialogTitle>
              <DialogContent>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  type="number"
                  onChange={(e) =>
                    setNewPatientWeight({
                      ...newPatientWeight,
                      weight: e.target.value * 1,
                    })
                  }
                  fullWidth
                  variant="standard"
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setIsDialogOpen(false)}>Annulla</Button>
                <Button
                  onClick={() => {
                    setIsDialogOpen(false);
                    setTimeout(() => {
                      dispatch(
                        insertPatientWeight(patientId, newPatientWeight),
                      ).then((_) => {
                        dispatch(getPatientWeights(patientId));
                      });
                      enqueueSnackbar("Peso inserito con successo", {
                        variant: "success",
                      });
                    }, 2500);
                    setNewPatientWeight({
                      weight: 0,
                      date: dayjs().format("YYYY-MM-DD"),
                    });
                  }}
                >
                  Conferma
                </Button>
              </DialogActions>
            </Dialog>
          ) : (
            renderQuestionsBySection(questionnaire.questions)
          )
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export { CompileQuestionnaire };
