import axios from "axios";

const login = (username, password) => {
  return axios
    .post("/api/login", "username=" + username + "&password=" + password)
    .then((response) => {
      if (response.data) {
        const authority = response.data.roles[0].authority;
        const tokenExists = response.data.tokenExists;

        return axios
          .get("/api/users/" + username, {
            params: {
              userType: authority.split("_")[1],
            },
          })
          .then((response) => {
            if (response.data) {
              response.data.authority = authority;
              response.data.tokenExists = tokenExists;
              localStorage.setItem("authUser", JSON.stringify(response.data));
            }

            return response.data;
          });
      }
    });
};
const logout = () => {
  return axios.get("/api/logout", null).then((response) => {
    localStorage.removeItem("authUser");
    localStorage.removeItem("menu");
  });
};

export const resetPassword = (username, userType) => {
  return axios
    .post("/api/users/resetPassword", null, {
      params: {
        username,
        userType,
      },
    })
    .then((response) => {
      if (response.data) {
        console.log(response.data);
      }
    });
};

export const savePassword = (json) => {
  return axios.post("/api/users/savePassword", json);
};

export const fitbitLogin = (patientId) => {
  return axios
    .get("/api/patients/" + patientId + "/fitbit-login", null)
    .then((response) => {
      window.location.replace(response.data);
    })
    .catch((error) => {
      console.error(error);
    });
};

const authServices = {
  login,
  logout,
  resetPassword,
  savePassword,
  fitbitLogin,
};

export default authServices;
