import axios from "axios";

const getPatientBloodAnalysis = (patientId) => {
  return axios.get("/api/patients/" + patientId + "/bloodAnalysis");
};
const getPatientQuestionnaire = (patientId, questionnaireId) => {
  return axios.get(
    "/api/patients/" + patientId + "/questionnaires/" + questionnaireId,
  );
};
const getPatient = (id) => {
  return axios.get("/api/patients/" + id);
};

const getPatients = () => {
  return axios.get("/api/patients", null);
};

const getPatientWeights = (id) => {
  return axios.get("/api/patients/" + id + "/weights");
};
const getPatientHRS = (id, startDate, endDate) => {
  return axios.get("/api/patients/" + id + "/hrs", {
    params: { startDate: startDate, endDate: endDate },
  });
};
const getPatientSteps = (id, startDate, endDate) => {
  return axios.get("/api/patients/" + id + "/activities/steps", {
    params: { startDate: startDate, endDate: endDate },
  });
};
const getSleepThreshold = (id) => {
  return axios.get("/api/patients/" + id + "/recommendations/thresholds/sleep");
};
const getHearthThreshold = (id) => {
  return axios.get("/api/patients/" + id + "/recommendations/thresholds/hr");
};
const getPatientSleep = (id, startDate, endDate) => {
  return axios.get("/api/patients/" + id + "/sleep/duration", {
    params: { startDate: startDate, endDate: endDate },
  });
};
const getPatientCalories = (id, startDate, endDate) => {
  return axios.get("/api/patients/" + id + "/activities/calories", {
    params: { startDate: startDate, endDate: endDate },
  });
};
const getPatientQuestionnaires = (id) => {
  return axios.get("/api/patients/" + id + "/questionnaires");
};
const getPatientStepsThreshold = (id) => {
  return axios.get(
    "/api/patients/" + id + "/recommendations/thresholds/activity",
  );
};
const insertBloodAnalysis = (id, data) => {
  return axios.post("/api/patients/" + id + "/bloodAnalysis", data);
};
const insertPatientWeight = (id, data) => {
  return axios.post("/api/patients/" + id + "/weights", data);
};
const insertStepsThreshold = (id, data) => {
  return axios.post(
    "/api/patients/" + id + "/recommendations/thresholds/activity",
    {
      steps_threshold: data,
    },
  );
};
const insertHearhThreshold = (id, data) => {
  const restRange = data.replace(" ", "").split("-");
  return axios.post("/api/patients/" + id + "/recommendations/thresholds/hr", {
    rest_min: restRange[0] * 1,
    rest_max: restRange[1] * 1,
  });
};
const insertSleepThreshold = (id, data) => {
  return axios.post(
    "/api/patients/" + id + "/recommendations/thresholds/sleep",
    {
      duration_ms_threshold: data * 60 * 60 * 1000,
    },
  );
};
const insertBloodAnalysisThresholds = (id, data) => {
  return axios.post(
    "/api/patients/" + id + "/recommendations/thresholds/bloodAnalysis",
    data,
  );
};
const compileQuestionnaire = (id, data) => {
  return axios.post("/api/patients/" + id + "/questionnaires", data);
};

const deletePatient = (id) => {
  return axios.delete("/api/patients/" + id);
};

const updatePatientWeight = (patientId, data, idW) => {
  return axios.put("/api/patients/" + patientId + "/weights/" + idW, data);
};

export const updatePatient = (patient, patientId) => {
  return axios
    .put("/api/patients/" + patientId, patient, {})
    .then((response) => {
      if (response.data) {
        console.log(response.data);
      }
    });
};

export const insertPatient = (patient) => {
  return axios.post("/api/patients", patient, {}).then((response) => {
    if (response.data) {
      console.log(response.data);
    }
  });
};

const getBloodAnalysisRange = (patientId) => {
  return axios.get(
    "/api/patients/" + patientId + "/recommendations/thresholds/bloodAnalysis",
  );
};

const patientService = {
  getPatientBloodAnalysis,
  getPatientQuestionnaire,
  getPatient,
  getPatientWeights,
  getPatientHRS,
  getPatientSteps,
  getPatientSleep,
  getPatientCalories,
  getPatientQuestionnaires,
  getPatientStepsThreshold,
  insertBloodAnalysis,
  insertPatientWeight,
  insertStepsThreshold,
  insertHearhThreshold,
  insertSleepThreshold,
  insertBloodAnalysisThresholds,
  compileQuestionnaire,
  getSleepThreshold,
  getHearthThreshold,
  deletePatient,
  updatePatientWeight,
  getPatients,
  updatePatient,
  insertPatient,
  getBloodAnalysisRange,
};

export default patientService;
