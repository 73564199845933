import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../actions/auth";
import {
  TextField,
  Box,
  Grid,
  IconButton,
  InputAdornment,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import logo from "../../icons/daily-health-app.png";
import { Link } from "@mui/material";

const selector = (state) => state.auth;

const FormLogin = () => {
  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { isLoggedIn, user } = useSelector(selector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleSubmit = (event) => {
    setLoading(true);
    setTimeout(() => {
      dispatch(login(username, password)).then(
        (result) => {
          setLoading(false);
        },
        (error) => {
          setLoading(false);
        },
      );
    }, 2000);
  };

  return (
    <>
      {isLoggedIn ? (
        user.authority === "ROLE_ADMIN" ? (
          navigate("/admin/doctors")
        ) : user.authority === "ROLE_DOCTOR" ? (
          navigate("/doctor/patients")
        ) : user.authority === "ROLE_PATIENT" && user.tokenExists === false ? (
          navigate("/patient/fitbit-login")
        ) : (
          navigate("/success-fitbit")
        )
      ) : (
        <Box
          style={{
            width: "100vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "60vh",
          }}
        >
          <Grid
            container
            spacing={3}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: "50vh" }}
          >
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <h2>VERONA EXPERIMENT</h2>
              <img src={logo} width={"60px"} alt="" />
            </Grid>
            <Grid item xs={12}>
              <TextField
                style={{ width: 200 }}
                size="small"
                required
                onChange={(event) => setUsername(event.target.value)}
                label="Username"
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                style={{ width: 200 }}
                size="small"
                required
                type={showPassword ? "text" : "password"}
                label="Password"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleTogglePassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={(event) => setPassword(event.target.value)}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <LoadingButton
                variant="outlined"
                loading={loading}
                onClick={(e) => handleSubmit()}
              >
                Login
              </LoadingButton>
            </Grid>
            <Grid item xs={12}>
              <Link
                style={{ fontFamily: "sans-serif" }}
                variant="outlined"
                onClick={(e) => navigate("/reset-password")}
              >
                Password dimenticata?
              </Link>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default FormLogin;
