import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useParams } from "react-router-dom";
import { Questionnaires } from "./Questionnaires";
import { AnalisiMediche } from "./BloodAnalysis";
import { Data } from "./Data";
import { Anagrafica } from "./Anagrafica";
import { useDispatch } from "react-redux";

const selector = (state) => state.auth;

function PatientDetail() {
  const { patientQuestionnaires, tabValue } = useSelector(selector);
  let { patientId } = useParams();
  patientId = patientId.replace("P", "");
  const dispatch = useDispatch();

  function handleChange(event, newValue) {
    dispatch({
      type: "TAB",
      payload: newValue,
    });
  }

  return (
    <>
      <TabContext value={tabValue}>
        <div style={{ height: 500, width: "calc(100vw - 302px)" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              position: "fixed",
              width: "100%",
              backgroundColor: "white",
              zIndex: 1,
            }}
          >
            <TabList onChange={handleChange}>
              <Tab label="Anagrafica" value="1" />
              <Tab label="Dati" value="2" />
              <Tab label="Analisi mediche" value="3" />
              <Tab label="Questionari" value="4" />
            </TabList>
          </Box>
          <TabPanel value="1" sx={{ p: 0, paddingTop: "68px" }}>
            <div>
              <Anagrafica patientId={patientId}></Anagrafica>
            </div>
          </TabPanel>
          <TabPanel value="2" sx={{ p: 0, paddingTop: "68px" }}>
            <div>
              <Data></Data>
            </div>
          </TabPanel>
          <TabPanel value="3" sx={{ p: 0, paddingTop: "68px" }}>
            <div>
              <AnalisiMediche></AnalisiMediche>
            </div>
          </TabPanel>
          <TabPanel value="4" sx={{ p: 0, paddingTop: "68px" }}>
            <div>
              {patientQuestionnaires != null ? (
                <Questionnaires isPatientQuestionnaires={true}></Questionnaires>
              ) : (
                <></>
              )}
            </div>
          </TabPanel>
        </div>
      </TabContext>
    </>
  );
}

export { PatientDetail };
