import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const selector = (state) => state.auth;

function AuthComponent(props) {
  const { isLoggedIn, error } = useSelector(selector);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: "error" });
    }
    setTimeout(() => {
      dispatch({
        type: "ERROR",
        payload: "",
      });
    }, 1000);
  }, [isLoggedIn, navigate, error, dispatch, enqueueSnackbar]);
  return <></>;
}

export { AuthComponent };
