import axios from "axios";

const getQuestionnaireTemplate = (questionnaireId) => {
  return axios.get("/api/questionnaires/templates/" + questionnaireId);
};
const getQuestionnairesTemplates = () => {
  return axios.get("/api/questionnaires/templates");
};
const createQuestionnaire = (questionnaire) => {
  return axios.post("/api/questionnaires/templates", questionnaire);
};

const questionnaireService = {
  createQuestionnaire,
  getQuestionnairesTemplates,
  getQuestionnaireTemplate,
};

export default questionnaireService;
