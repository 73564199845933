import { LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT, PATIENTS } from "../actions/types";

const user = JSON.parse(localStorage.getItem("authUser"));
const menu = JSON.parse(localStorage.getItem("menu"));
const initialState = user
  ? {
      isLoggedIn: true,
      user,
      patientId: null,
      patientWeight: [],
      patientActivity: [],
      patientSleep: [],
      patientStepsThreshold: null,
      patientSteps: [],
      bloodAnalysisRange: [],
      patientHearth: [],
      patientsListLoading: true,
      patientsList: [],
      sleepAvarage: 0,
      hrsAvarage: 0,
      patientBloodAnalysis: [],
      stepsAvarage: 0,
      doctorsList: [],
      questionnaire: {},
      questionnaires: [],
      menu,
      error: null,
      patientQuestionnaires: [],
      patientHRS: [],
      success: null,
      tabValue: "1",
    }
  : {
      isLoggedIn: false,
      user: null,
      patientId: null,
      patientsListLoading: true,
      hrsAvarage: 0,
      patientBloodAnalysis: [],
      patientsList: [],
      patientWeight: [],
      patientActivity: [],
      sleepAvarage: 0,
      patientSleep: [],
      patientSteps: [],
      patientHearth: [],
      patientHeartRates: [],
      bloodAnalysisRange: [],
      stepsAvarage: 0,
      error: null,
      questionnaires: [],
      patientStepsThreshold: null,
      questionnaire: {},
      doctorsList: [],
      history: [],
      menu: null,
      patient: null,
      patientQuestionnaires: [],
      patientHRS: [],
      success: null,
      tabValue: "1",
    };

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  let updatedPatientsList;
  let updatedDoctorsList;
  let newmenu;

  switch (type) {
    case "DELETE_PATIENT":
      // Rimuovi il paziente con l'ID corrispondente dalla lista dei pazienti
      updatedPatientsList = state.patientsList.filter(
        (patient) => patient.id !== payload,
      );
      return {
        ...state,
        patientsList: updatedPatientsList,
      };
    case "DELETE_DOCTOR":
      // Rimuovi il dottore con l'ID corrispondente dalla lista dei dottori
      updatedDoctorsList = state.doctorsList.filter(
        (doctor) => doctor.id !== payload,
      );
      return {
        ...state,
        doctorsList: updatedDoctorsList,
      };
    case "PATIENT_HRS":
      return {
        ...state,
        patientHRS: payload,
      };
    case "SLEEP_THRESHOLD":
      return {
        ...state,
        sleepThreshold: payload,
      };
    case "HR_THRESHOLD":
      return {
        ...state,
        hrThreshold: payload,
      };
    case "PATIENT_ID":
      return {
        ...state,
        patientId: payload,
      };
    case "DOCTORS":
      return {
        ...state,
        doctorsList: payload,
      };
    case "PATIENT_STEPS_AVG":
      return {
        ...state,
        stepsAvarage: payload,
      };
    case "PATIENT_HEARTH_AVG":
      return {
        ...state,
        hrsAvarage: payload,
      };
    case "PATIENT_SLEEP_AVG":
      return {
        ...state,
        sleepAvarage: payload,
      };
    case "BLOOD_ANALYSIS_RANGE":
      return {
        ...state,
        bloodAnalysisRange: payload,
      };
    case "MENU": {
      return {
        ...state,
        menu: payload,
      };
    }
    case "MENU_ACTION":
      newmenu = { ...state.menu };
      newmenu[payload.authority].map((vm) => {
        if (vm.label === payload.action) {
          vm.active = true;
        } else {
          vm.active = false;
        }
        return vm;
      });
      localStorage.setItem("menu", JSON.stringify(newmenu));
      return {
        ...state,
        menu: newmenu,
      };
    case "ERROR":
      return {
        ...state,
        error: payload,
      };
    case "SUCCESS":
      return {
        ...state,
        success: payload,
      };
    case "PATIENT_STEPS_THRESHOLD":
      return {
        ...state,
        patientStepsThreshold:
          payload?.length > 0 ? payload[0].stepsThreshold : "-",
      };
    case "PATIENT_BLOOD_ANALYSIS":
      return {
        ...state,
        patientBloodAnalysis: payload,
      };
    case "PATIENT_WEIGHTS":
      return {
        ...state,
        patientWeight: payload,
      };

    case "PATIENT_HEARTH":
      return {
        ...state,
        patientHearth: payload,
      };
    case "PATIENT_SLEEP":
      return {
        ...state,
        patientSleep: payload,
      };
    case "PATIENT_STEPS":
      return {
        ...state,
        patientSteps: payload,
      };
    case "data":
      break;
    case "PATIENT_QUESTIONNAIRES":
      return {
        ...state,
        patientQuestionnaires: payload,
      };
    case "PATIENT":
      return {
        ...state,
        patient: payload,
      };
    case "QUESTIONNAIRES":
      return {
        ...state,
        questionnaires: payload,
        questionnaire: {},
      };
    case "PATIENT_QUESTIONNAIRE":
      return {
        ...state,
        questionnaire: {
          ...payload,
          isPatient: true,
        },
      };
    case "QUESTIONNAIRE":
      return {
        ...state,
        questionnaire: payload,
      };
    case "REFRESH_PATIENTS":
      return {
        ...state,
        patientsList: [],
      };
    case "REFRESH_QUESTIONNAIRES":
      return {
        ...state,
        questionnaires: [],
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        patientsListLoading: true,
        patientsList: [],
      };
    case PATIENTS:
      return {
        ...state,
        patientsList: payload,
      };
    case "TAB":
      return {
        ...state,
        tabValue: payload,
      };
    default:
      return state;
  }
}
