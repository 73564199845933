/**
 * login/logout
 */
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const LOGOUT_FAIL = "LOGOUT_FAIL";
export const LOGIN_FITBIT = "LOGIN_FITBIT";
export const LOGIN_FITBIT_OK = "LOGIN_FITBIT_OK";
export const LOGIN_FITBIT_KO = "LOGIN_FITBIT_KO";

/**
 * app message
 */
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

/**
 * user active
 */
export const USER_PROFILE = "USER_PROFILE";

/**
 * content active
 */
export const HEALTH_STATUS = "HEALTH_STATUS";
export const PATIENTS_LIST_LOADING = "PATIENTS_LIST_LOADING";
export const PATIENTS = "PATIENTS";
export const DOCTORS = "DOCTORS";
export const INSERT_PATIENT = "INSERT_PATIENT";
export const DELETE_PATIENT = "DELETE_PATIENT";
export const DELETE_DOCTOR = "DELETE_DOCTOR";
