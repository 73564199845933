import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Backdrop,
  CircularProgress,
  Grid,
} from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  insertHearhThreshold,
  insertSleepThreshold,
  insertStepsThreshold,
} from "../../actions/patient";
import { PropTypes } from "prop-types";

Soglia.propTypes = {
  name: PropTypes.string,
};

const selector = (state) => state.auth;

function Soglia(props) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  const { name } = props;
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { patient, patientStepsThreshold, hrThreshold, sleepThreshold } =
    useSelector(selector);
  const actions = {
    Passi: {
      action: () => {
        dispatch(insertStepsThreshold(patient.id, value));
      },
      type: "number",
      measure: "",
    },
    Battiti: {
      action: () => {
        dispatch(insertHearhThreshold(patient.id, value));
      },
      type: "text",
      measure: "bpm medi",
    },
    Sonno: {
      action: () => {
        dispatch(insertSleepThreshold(patient.id, value));
      },
      type: "number",
      measure: "ore",
    },
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Button variant="outlined" onClick={() => setOpen(true)}>
        {" "}
        Soglie
      </Button>
      <Dialog open={open}>
        <DialogTitle>
          {name +
            ("" !== actions[name].measure
              ? "(" + actions[name].measure + ")"
              : "")}
        </DialogTitle>
        <DialogContent style={{ padding: "10px" }}>
          <Grid container spacing={4}>
            <Grid item>
              <TextField
                label="valore soglia"
                value={
                  name === "Passi"
                    ? patientStepsThreshold
                    : name === "Battiti"
                      ? hrThreshold
                      : name === "Sonno"
                        ? sleepThreshold
                        : "-"
                }
                disabled
              ></TextField>
            </Grid>
            <Grid item>
              <TextField
                label={"nuovo valore"}
                placeholder={name === "Battiti" ? "min - max" : ""}
                type={actions[name].type}
                onChange={(event) => {
                  setValue(event.target.value);
                }}
              ></TextField>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Annulla</Button>
          <Button
            onClick={() => {
              setIsLoading(true);
              actions[name].action();
              setTimeout(() => {
                setIsLoading(false);
              }, 2500);
              setOpen(false);
            }}
          >
            Salva
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export { Soglia };
