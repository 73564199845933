import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { getQuestionnairesTemplates } from "../../actions/questionnaire";
import { getPatientQuestionnaires } from "../../actions/patient";
import { useNavigate, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import { PropTypes } from "prop-types";

const selector = (state) => state.auth;

Questionnaires.propTypes = {
  isPatientQuestionnaires: PropTypes.bool,
};

function Questionnaires(props) {
  const { questionnaires, patientQuestionnaires } = useSelector(selector);
  const [isQuestionnaireLoading, setIsQuestionnaireLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const thisroute = useLocation();
  let { patientId } = useParams();
  patientId = patientId?.replace("P", "");

  useEffect(() => {
    if (props.isPatientQuestionnaires == null && isQuestionnaireLoading) {
      dispatch(getQuestionnairesTemplates());
    } else if (
      props.isPatientQuestionnaires != null &&
      isQuestionnaireLoading
    ) {
      dispatch(getPatientQuestionnaires(patientId));
    }
    setIsQuestionnaireLoading(false);
  }, [
    props.isPatientQuestionnaires,
    isQuestionnaireLoading,
    dispatch,
    patientId,
  ]);

  const columnsNew =
    props.isPatientQuestionnaires != null
      ? [
          {
            field: "name",
            headerName: "Nome Questionario",
            width: 450,
            sortable: false,
            renderCell: (cellValue) =>
              patientQuestionnaires
                .filter(
                  (questionnaire) => questionnaire.id === cellValue.row.id,
                )
                .pop().questionnaireTemplate.name,
          },
          {
            field: "score",
            headerName: "Punteggio",
            width: 350,
            sortable: false,
            renderCell: (cellValue) =>
              cellValue.row.name !==
              "INDICE PROGNOSTICO MULTIDIMENSIONALE (MPI)"
                ? cellValue.row.score
                : cellValue.row.score / 100 || 0,
          },
          {
            field: "submissionDate",
            headerName: "Data compilazione",
            width: 350,
            renderCell: (cellValue) =>
              dayjs(cellValue.row.submissionDate).format("YYYY-MM-DD"),
          },
        ]
      : [
          {
            field: "name",
            headerName: "Nome",
            width: 450,
            renderCell: (cellValue) => cellValue.row.name,
          },
          {
            field: "maxScore",
            headerName: "Punteggio massimo",
            width: 300,
            renderCell: (cellValue) =>
              cellValue.row.name !==
              "INDICE PROGNOSTICO MULTIDIMENSIONALE (MPI)"
                ? cellValue.row.maxScore
                : 1,
          },
        ];

  return (
    <div
      style={{
        height: 500,
        paddingBottom: 90,
        width: "calc(100vw - 302px)",
        marginLeft: 32,
        marginRight: 32,
      }}
    >
      {props.isPatientQuestionnaires === true ? (
        <>
          <Box sx={{ textAlign: "left", marginBottom: "16px" }}>
            <Button
              variant="outlined"
              onClick={() => {
                navigate(thisroute.pathname + "/questionnaires/new");
              }}
            >
              Compila Questionario
            </Button>
          </Box>
          {patientQuestionnaires != null ? (
            <DataGrid
              rows={patientQuestionnaires.map((questionnaire) => {
                if (
                  questionnaire.questionnaireTemplate.name ===
                  "INDICE PROGNOSTICO MULTIDIMENSIONALE (MPI)"
                ) {
                  return {
                    id: questionnaire.id,
                    name: questionnaire.name,
                    score: questionnaire.score / 100 + "/1",
                    submissionDate: questionnaire.submissionDate,
                  };
                } else {
                  return {
                    id: questionnaire.id,
                    name: questionnaire.name,
                    score:
                      questionnaire.score +
                      "/" +
                      questionnaire.questionnaireTemplate.maxScore,
                    submissionDate: questionnaire.submissionDate,
                  };
                }
              })}
              columns={columnsNew}
              rowSelection={false}
              rowsPerPageOptions={[5]}
              pageSizeOptions={[5, 10, 25]}
              initialState={{
                sorting: {
                  sortModel: [{ field: "submissionDate", sort: "desc" }],
                },
              }}
              onRowClick={(cellValue) => {
                navigate(
                  thisroute.pathname + "/questionnaires/" + cellValue.row.id,
                );
              }}
            />
          ) : (
            <></>
          )}
        </>
      ) : (
        <div style={{ height: 400 }}>
          <Box sx={{ textAlign: "left", marginBottom: "16px" }}>
            <Button
              variant="outlined"
              onClick={() => {
                navigate(thisroute.pathname + "/new");
              }}
            >
              Crea questionario
            </Button>
          </Box>
          <DataGrid
            rows={questionnaires.map((questionnaire) => {
              return {
                id: questionnaire.id,
                name: questionnaire.name,
                maxScore: questionnaire.maxScore || 0,
              };
            })}
            columns={columnsNew}
            rowSelection={false}
            rowsPerPageOptions={[5]}
            pageSizeOptions={[5, 10, 25]}
            onRowClick={(cellValue) => {
              navigate(thisroute.pathname + "/" + cellValue.row.id);
            }}
          />
        </div>
      )}
    </div>
  );
}

export { Questionnaires };
