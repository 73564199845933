import { ResponsiveBar } from "@nivo/bar";
import React from "react";
import { convertMinutesToHoursAndMinutes } from "../util/utilities.js";

import { PropTypes } from "prop-types";

BarChart.propTypes = {
  data: PropTypes.array,
  keys: PropTypes.array,
  indexBy: PropTypes.string,
  legendX: PropTypes.string,
  legendY: PropTypes.string,
  avg: PropTypes.number,
  time: PropTypes.bool,
};

function BarChart(props) {
  return (
    <>
      <div style={{ height: 500 }}>
        <ResponsiveBar
          // data={props.data}
          // keys={props.keys}
          // indexBy={props.indexBy}
          {...props}
          label={
            props.time
              ? (bar) => convertMinutesToHoursAndMinutes(bar.value)
              : undefined
          }
          margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
          padding={0.3}
          markers={
            props.avg != null
              ? [
                  {
                    axis: "y",
                    value: props.avg,
                    lineStyle: {
                      stroke: "rgba(0, 0, 0, .35)",
                      strokeWidth: 2,
                    },
                    legend:
                      "valore medio: " +
                      (props.time
                        ? convertMinutesToHoursAndMinutes(props.avg)
                        : props.avg),
                    legendOrientation: "horizontal",
                  },
                ]
              : []
          }
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          borderColor={{
            from: "color",
            modifiers: [["darker", 1.6]],
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: props.legendX,
            legendPosition: "middle",
            legendOffset: 32,
          }}
          axisLeft={
            props.time
              ? {
                  legend: props.legendY,
                  legendPosition: "middle",
                  legendOffset: -40,
                  tickValues: 0,
                  format: (e) => convertMinutesToHoursAndMinutes(e),
                }
              : {
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: props.legendY,
                  legendPosition: "middle",
                  legendOffset: -50,
                }
          }
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{
            from: "color",
            modifiers: [["darker", 1.6]],
          }}
          tooltip={({ id, value }) => (
            <div
              style={{
                background: "white",
                padding: "9px 12px",
                border: "1px solid #ccc",
              }}
            >
              <div>
                {id}:{" "}
                {props.time ? (
                  <strong>{convertMinutesToHoursAndMinutes(value)}</strong>
                ) : (
                  <strong>{value}</strong>
                )}
              </div>
            </div>
          )}
          legends={[
            {
              dataFrom: "keys",
              anchor: "bottom-right",
              direction: "column",
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: "left-to-right",
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: "hover",
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          role="application"
          ariaLabel="Nivo bar chart demo"
          // barAriaLabel={e=>e.id+": "+e.formattedValue+" in country: "+e.indexValue}
        />
      </div>
    </>
  );
}

export { BarChart };
