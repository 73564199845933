import doctorServices from "../services/doctor.services";
import { enqueueSnackbar } from "notistack";

export const getDoctors = () => (dispatch) => {
  doctorServices.getDoctors().then((data) => {
    try {
      var json = JSON.parse(JSON.stringify(data.data));
      if (!json || typeof json !== "object") {
        dispatch({
          type: "LOGOUT",
          payload: null,
        });
        return;
      }
    } catch (e) {
      dispatch({
        type: "LOGOUT",
        payload: null,
      });
    }
    dispatch({
      type: "DOCTORS",
      payload: data.data,
    });
  });
};
export const deleteDoctor = (doctorId) => (dispatch) => {
  return doctorServices.deleteDoctor(doctorId).then(
    (response) => {
      dispatch({
        type: "DELETE_DOCTOR",
        payload: doctorId,
      });
      enqueueSnackbar("Dottore eliminato con successo", { variant: "success" });
    },
    (error) => {
      dispatch({
        type: "ERROR",
        payload: error.message,
      });
      return Promise.resolve(error);
    },
  );
};
