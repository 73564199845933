import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPatients } from "../../actions/patient";
import { getDoctors } from "../../actions/doctor";
import * as React from "react";
import Menu from "@mui/material/Menu";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Delete } from "@mui/icons-material";
import AlertDialog from "../util/AlertDialog.js";

const selector = (state) => state.auth;

function HomeDoctor() {
  const thisroute = useLocation();
  const [patientsListLoading, setPatientsListLoading] = useState(true);
  const dispatch = useDispatch();
  const { isLoggedIn, patientsList } = useSelector(selector);
  const navigate = useNavigate();
  const [patientId, setPatientId] = useState(null);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch({
        type: "TAB",
        payload: "1",
      });
      if (patientsListLoading) {
        setPatientsListLoading(false);
        dispatch(getPatients());
        dispatch(getDoctors());
      }
    } else {
      navigate("/form-login");
    }
  }, [patientsListLoading, isLoggedIn, patientsList, navigate, dispatch]);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const columnsNew = [
    {
      field: "firstName",
      headerName: <strong>Nome</strong>,
      align: "left",
      width: 200,
    },
    {
      field: "lastName",
      headerName: <strong>Cognome</strong>,
      align: "left",
      width: 200,
    },
    {
      field: "gender",
      headerName: <strong>Sesso</strong>,
      align: "left",
      width: 150,
    },
    {
      field: "birthDate",
      headerName: <strong>Data di nascita</strong>,
      align: "left",
      width: 150,
    },
    {
      field: "delete",
      headerName: "",
      align: "center",
      width: 100,
      renderCell: (cellValue) => (
        <Button color="error">
          <Delete></Delete>
        </Button>
      ),
    },
  ];

  const [anchorPatientMenu, setAnchorPatientMenu] = useState(null);

  return (
    <>
      <AlertDialog
        open={open}
        setOpen={setOpen}
        title="Elimina paziente"
        message="Sei sicuro di voler eliminare il paziente?"
        patientId={patientId}
        action="delete_patient"
      ></AlertDialog>
      <Menu
        anchorEl={anchorPatientMenu}
        open={Boolean(anchorPatientMenu)}
        onClose={() => {
          setAnchorPatientMenu(null);
        }}
      ></Menu>

      <div
        style={{
          height: 500,
          maxWidth: "calc(100vw - 302px)",
          marginLeft: "32px",
        }}
      >
        <Box
          style={{ width: "100%", textAlign: "left", paddingBottom: "14px" }}
        >
          <Button
            startIcon={<PersonAddAltIcon></PersonAddAltIcon>}
            sx={{ marginRight: "8px" }}
            title="aggiungi paziente"
            variant="outlined"
            onClick={() => {
              navigate(thisroute.pathname + "/new");
            }}
          >
            Aggiungi paziente
          </Button>
          <Button
            startIcon={<FileDownloadIcon></FileDownloadIcon>}
            disabled
            variant="outlined"
            title="esporta lista"
            onClick={() => {}}
          >
            Esporta
          </Button>
        </Box>
        <DataGrid
          rows={patientsList}
          columns={columnsNew}
          pageSize={10}
          loading={patientsListLoading === true}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[10, 20, 50]}
          disableRowSelectionOnClick
          onCellClick={(cellValue) => {
            if (cellValue.field === "delete") {
              setPatientId(cellValue.row.id);
              handleClickOpen();
            } else {
              navigate("/doctor/patients/P" + cellValue.row.id);
              dispatch({
                type: "PATIENT_ID",
                payload: cellValue.row.id,
              });
            }
          }}
        />
      </div>
    </>
  );
}

export { HomeDoctor };
